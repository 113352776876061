import { Model } from '@vuex-orm/core'

export default class MobileDrawer extends Model {
  static entity = '_mobiledrawer'

  static fields () {
    return {
      id: this.uid(),
      isOpen: this.attr(false),
      params: this.attr(null)
    }
  }

  async open (params) {
    this.isOpen = true
    this.params = params
    await this.$save()
  }

  async close () {
    this.isOpen = false
    this.params = null
    await this.$save()
  }
}
