import { Model } from '@vuex-orm/core'

class Snackbar extends Model {
  static entity = '_snackbar'

  static fields () {
    return {
      id: this.uid(),
      isOpen: this.attr(false),
      type: this.attr(null),
      params: this.attr(null)
    }
  }

  async open (params) {
    this.isOpen = true
    this.params = params
    await this.$save()
    setTimeout(() => {
      this.close()
    }, params?.timeout || 5000)
  }

  async close () {
    this.isOpen = false
    this.params = null
    await this.$save()
  }
}

export default Snackbar
