import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCol,{staticClass:"py-0",attrs:{"cols":"12"}},[_c(VRow,_vm._l((_vm.items),function(item,index){return _c(VCol,_vm._b({key:index,staticClass:"py-0"},'v-col',_vm.fieldCols(item),false),[(item.group)?[(_vm.inContext(item))?_c(item.group,{key:[item.model, '-', index].join(''),tag:"component",attrs:{"ctx":_vm.morphedCtx(item),"value":_vm.fieldVal(item),"item":item},on:{"input":function($event){_vm.$emit(['update:', item.model].join(''), $event)}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var scopedAttrs = ref.scopedAttrs;
return [(item.fields)?_vm._l((item.fields),function(field,index){return _c(VRow,{key:index,attrs:{"no-gutters":""}},[(_vm.inContext(field))?[(field.label)?_c('t-orm-label',{attrs:{"item":field}}):_vm._e()]:_vm._e(),_c(VCol,_vm._b({staticClass:"py-0"},'v-col',_vm.fieldCols(item),false),[_c('e-input-wrapper',{key:[field.model, '-', index].join(''),attrs:{"ctx":_vm.morphedCtx(item),"item":field,"value":_vm.fieldVal(field)},on:{"input":function($event){_vm.$emit(['update:', field.model].join(''), $event)}}})],1)],2)}):(item.renderer)?[_c(item.renderer.component,_vm._b({tag:"component",attrs:{"item":item,"value":_vm.fieldVal(item)},on:{"input":function($event){_vm.$emit(['update:', item.model].join(''), $event)}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var items = ref.items;
var on = ref.on;
var reactive = ref.reactive;
return _vm._l((items),function(field,index){return _c(VRow,{key:index,attrs:{"no-gutters":""}},[(_vm.inContext(field))?[(field.label)?_c('t-orm-label',{attrs:{"item":field}}):_vm._e()]:_vm._e(),_c(VCol,_vm._b({staticClass:"py-0"},'v-col',_vm.rendererFieldCols(item),false),[_c('e-input-wrapper',{key:[field.model, '-', index].join(''),attrs:{"ctx":_vm.morphedCtx(item),"item":field,"dynamic":true,"value":_vm.scopedval(reactive, field)},on:{"input":function($event){return _vm.callListeners(on, field, $event)}}})],1)],2)})}}],null,true)},'component',scopedAttrs || item.renderer.attrs,false))]:_vm._e()]}}],null,true)}):_vm._e()]:[(_vm.inContext(item))?[(item.label && item.type !=='dynamic-field' && _vm._.get(item, 'attrs.visible', true))?_c('t-orm-label',{class:item.labelClassName,attrs:{"item":item}}):_vm._e(),(item.type !== 'dynamic-field')?[_c('e-input-wrapper',{key:[item.model, '-', index].join(''),attrs:{"ctx":_vm.morphedCtx(item),"item":item,"dynamic":_vm._.get(item.attrs, 'dynamic', false),"value":_vm.fieldVal(item)},on:{"input":function($event){_vm.$emit(['update:', item.model].join(''), $event)}}})]:[_c('e-input-dynamic',{attrs:{"header":item.header,"currentDynamicField":_vm.currentDynamicField,"field-component":item.fieldComponent,"field-names":item.models,"fields-labels":item.labels,"label":item.label,"field-attrs":item.fieldAttrs,"field-provider":item.fieldProvider,"name-pattern":item.namePattern,"values":_vm.fieldsValues(item),"initial-count":item.initialCount,"max":item.max},on:{"update:currentDynamicField":function($event){_vm.currentDynamicField=$event},"update:current-dynamic-field":function($event){_vm.currentDynamicField=$event},"input":function($event){_vm.$emit(['update:', _vm.currentDynamicField].join(''), $event)}},scopedSlots:_vm._u([{key:"label",fn:function(ref){
var label = ref.label;
return [_c('t-orm-label',{attrs:{"item":{label:label}}})]}}],null,true)})]]:_vm._e()]],2)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }