import Search from '~/models/abstracts/Search'

export class OrganizationSearch extends Search {
  static entity = 'organizationsearch'

  static fields () {
    return {
      id: this.attr(null),
      name: this.attr(null),
      edrpou: this.attr(null)
    }
  }

  toString () {
    return `${this.name}`
  }
}

export default OrganizationSearch
