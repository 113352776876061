export class IntervalRequest {
  static errorsTexts = {
    rejectCondition: 'rejectCondition',
    maxNumbersReached: 'maxNumbersReached'
  }

  constructor (request, { interval = 1e3, count = 300, exponential = 1.2, maxDelay = 1e4 } = {}) {
    this.request = request
    this.interval = interval
    this.count = count
    this.exponential = exponential
    this.maxDelay = maxDelay
    this.stoped = false
    this.response = null
  }

  wait (seconds) {
    return new Promise(resolve => setTimeout(resolve, seconds))
  }

  stopExponential = () => {
    this.stoped = true
  }

  async startExponential (resolveCondition, rejectCondition = () => false, maximumCallsError = true) {
    let response = null
    let interval = this.interval
    let resolved = false
    let rejected = false

    let error = null
    do {
      if (this.stoped) {
        return null
      }
      try {
        response = await this.request()
        this.response = response
      } catch (e) {
        error = e
        break
      }
      if (resolveCondition(response)) {
        resolved = true
        break
      } else if (rejectCondition(response)) {
        rejected = true
        break
      }
      await this.wait(interval)
      interval *= this.exponential
    } while (interval <= this.maxDelay)

    if (resolved) {
      return response
    } else if (rejected) {
      throw new Error('Rejected by reject condition')
    } else if (error) {
      throw error
    } else if (maximumCallsError) {
      throw new Error('Maximum number of calls reached')
    } else {
      return null
    }
  }

  start (resolveCondition, rejectCondition = () => false) {
    return new Promise((resolve, reject) => {
      let requesting = false
      let iterator = 0
      const interval = setInterval(async () => {
        try {
          if (iterator > this.count) {
            clearInterval(interval)
            reject(new Error('Maximum number of calls reached'))
          }
          iterator++
          if (!requesting) {
            requesting = true
            const response = await this.request()
            if (resolveCondition(response)) {
              clearInterval(interval)
              resolve(response)
            } else if (rejectCondition(response)) {
              clearInterval(interval)
              reject(new Error('Rejected by reject condition'))
            }
          }
        } catch (e) {
          clearInterval(interval)
          reject(e)
        } finally {
          requesting = false
        }
      }, this.interval)

      this.stop = () => {
        clearInterval(interval)
        resolve(null)
      }
    })
  }
}
