<template lang="pug">
  div(class="eds-iit-checkbox-sign-options")
    v-btn(
      v-for="(option, idx) in options"
      :key="idx"
      :id="setBtnIds(option.key)"
      class="eds-iit-checkbox-sign-options__option"
      @click="option.onClick(option.key)"
    )
      div(class="eds-iit-checkbox-sign-options__option-content")
        div(class="eds-iit-checkbox-sign-options__option-icon")
          e-svg-icon(size="xxlg") {{ option.iconName }}
        h4(class="eds-iit-checkbox-sign-options__option-title") {{ option.title }}
      div(class="eds-iit-checkbox-sign-options__option-action")
        e-svg-icon(size="sm") arrow-next
</template>

<script>
import ESvgIcon from '~/components/elements/icons/e-svg-icon'
import responsive from '~/mixins/pages/responsive'
import edsIitCheckboxSteps from '~/modules/eds-iit-checkbox/mixins/edsIitCheckboxSteps'
import EDividerLineText from '~/components/elements/dividers/e-divider-line-text'

export default {
  name: 'EdsIitCheckboxSignOptions',
  components: {
    ESvgIcon,
    EDividerLineText
  },
  mixins: [responsive, edsIitCheckboxSteps],
  computed: {
    options () {
      return [
        {
          key: this.steps.diiaSign,
          title: 'Дія.Підпис',
          iconName: 'diia',
          eventName: 'click_diia',
          onClick: key => this.handleClick(key)
        },
        {
          key: this.steps.fileSign,
          title: 'Ключем ЕЦП (КЕП)',
          iconName: 'key-in-box',
          eventName: 'click_ecp',
          onClick: key => this.handleClick(key)
        },
        {
          key: this.steps.smartIdRead,
          title: 'SmartID',
          iconName: 'smart-id',
          eventName: 'click_smartid',
          onClick: key => this.handleClick(key)
        }
      ]
    }
  },
  methods: {
    handleClick (key) {
      this.$emit('select', key)
      window.dataLayer && window.dataLayer.push({
        event: this._.get(this._.find(this.options, o => o.key === key), 'eventName'),
        email: this._.get(this.$User, 'email')
      })
    },
    setBtnIds (key) {
      if (key === this.steps.diiaSign) {
        return 'diia'
      } else if (key === this.steps.fileSign) {
        return 'ecp'
      } else if (key === this.steps.smartIdRead) {
        return 'smartid'
      }
    }
  }
}
</script>

<style scoped lang="scss">
.eds-iit-checkbox-sign-options {
  display: flex;
  flex-direction: column;
  gap: 16px;

  &__option {
    display: flex;
    align-items: center;
    justify-content: space-between;

    width: 100%;
    height: auto !important;

    border-radius: 16px;
    border: 2px solid rgba(0, 0, 0, 0.08);

    padding: 24px !important;

    background: none !important;

    &-content {
      display: flex;
      align-items: center;
    }

    &-title {
      padding-left: 8px;
      color: #000;
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
    }
  }
}
</style>
