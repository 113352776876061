<template lang="pug">
  span(v-if="value")
    v-row(no-gutters)
      v-col(cols="auto")
        span(style="cursor:pointer" @mouseover="showIcon = true" @mouseleave="showIcon = false" @click="copyToClipboard")
          | {{value}}
      v-col(cols="auto" v-if="showIcon" class="ml-1")
        e-svg-icon(style="opacity:0.5" size="xs")
          | copy
</template>

<script>
import ESvgIcon from '~/components/elements/icons/e-svg-icon'
import clipboard from '~/mixins/global/_clipboard'
export default {
  components: { ESvgIcon },
  mixins: [clipboard],
  props: {
    value: {
      type: String,
      default: null
    }
  },
  data: () => ({
    showIcon: false
  }),
  methods: {
    copyToClipboard () {
      try {
        this.copy(this.value)
        this.$notification.success(this.$t('Copied to clipboard'))
      } catch (e) {
        this.$handlers.error(e, this)
      }
    }
  }
}
</script>
