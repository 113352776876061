import MobileDrawer from '~/modules/mobile-drawer/models/MobileDrawer'

const mobileDrawer = {
  computed: {
    mobileDrawer () {
      return MobileDrawer.query().first()
    }
  }
}

export default mobileDrawer
