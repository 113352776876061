import { VBtn } from 'vuetify/lib/components/VBtn';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSubheader } from 'vuetify/lib/components/VSubheader';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VMenu,{attrs:{"value":_vm.value,"offset-x":""},on:{"input":function($event){return _vm.$emit('input')}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._b({attrs:{"icon":""},on:{"!click":function($event){$event.stopPropagation();return _vm.$emit('onSettings', _vm.tag.id)}}},'v-btn',attrs,false),[_c('e-svg-icon',[_vm._v("three-dots-outlined")])],1)]}}])},[_c(VList,{staticClass:"menu-list"},[_vm._l((_vm.tagActions),function(action,idx){return [(action.innerMenu)?[_c(VMenu,{attrs:{"right":"","offset-x":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(_vm.isVisible(action, _vm.tag))?_c(VListItem,_vm._g({key:idx,staticClass:"menu-list__item"},on),[_c(VListItemTitle,{staticClass:"text-14px dark"},[_vm._v(_vm._s(_vm.$t(action.text)))]),(action.icon)?_c(VListItemIcon,{staticClass:"ma-0 h-100 d-flex align-center"},[_c('e-svg-icon',{attrs:{"name":action.icon}})],1):_vm._e()],1):_vm._e()]}}],null,true)},[_c(VList,{staticClass:"menu-list"},[_c(VSubheader,[_vm._v(_vm._s(_vm.$t('In documents list')))]),_vm._l((action.innerMenu),function(option,idx){return _c(VListItem,{key:idx,staticClass:"menu-list__item",on:{"click":function($event){return _vm.handleOptionClick(option)}}},[_c(VListItemTitle,{staticClass:"text-14px dark"},[_vm._v(_vm._s(_vm.$t(option.text)))]),(_vm.visibilityTags[_vm.tag.id] === option.value)?_c(VListItemIcon,{staticClass:"ma-0 h-100 d-flex align-center"},[_c('e-svg-icon',[_vm._v("check-primary")])],1):_vm._e()],1)})],2)],1)]:[(_vm.isVisible(action))?_c(VListItem,{key:idx,staticClass:"menu-list__item",on:{"click":function($event){return action.call(_vm.tag)}}},[_c(VListItemTitle,{staticClass:"text-14px",class:[_vm.isRemoveAction(action) ? 'color-error' : 'dark']},[_vm._v(_vm._s(_vm.$t(action.text)))]),(action.icon)?_c(VListItemIcon,{staticClass:"ma-0 h-100 d-flex align-center"},[_c('e-svg-icon',{attrs:{"name":action.icon}})],1):_vm._e()],1):_vm._e()]]})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }