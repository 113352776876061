export default async function () {
  if ('serviceWorker' in navigator) {
    const regs = await navigator.serviceWorker.getRegistrations()
    const reg = regs[0]
    if (reg) {
      setInterval(() => {
        reg.update()
      }, 6e4)

      reg.addEventListener('updatefound', () => {
        const worker = reg.installing

        worker.addEventListener('statechange', () => {
          if (worker.state === 'activated') {
            window.location.reload()
          }
        })
      })
    }
  }
}
