<template lang="pug">
  v-menu(
    v-model="menu"
    :close-on-click="closeOnClick"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-x
    :nudge-top="26"
    min-width="290px"
    class="e-input-datetime"
    :disabled="_.get($attrs, 'disabled', false)"
    @input="onMenuInput"
  )
    template(v-slot:activator="{ on }")
      slot(name="activator" :listeners="on" :value="getFieldFormat")
        v-text-field(
          :value="getFieldFormat"
          v-bind="$attrs"
          :clearable= "clearable"
          @click:clear = "clear"
          :class="$attrs.classes"
          v-on="on"
        )
          template(v-slot:prepend-inner)
            e-svg-icon(v-if="prependIcon" class="mr-2" style="margin-top: 2px;") calendar-event
    v-card(class="elevation-0 e-input-datetime__card")
      v-card-text(class="pa-0")
        v-date-picker(v-if="['date', 'datetime'].includes(type)"
          v-model="dateRange"
          :locale="$config.locale.code"
          color="primary"
          first-day-of-week="1"
          class="elevation-0"
          :min="min"
          :max="max"
          :title-date-format="titleDate"
          @input="dateChange"
          :key="datePickerKey"
          range
          scrollable
        )
          template(v-slot:default)
            div(
              v-if="fastDates"
              class="e-input-datetime__select"
            )
              v-select(
                v-if="fastDatesYearSelect"
                v-model="year"
                :items="years"
                :label="$t('Select year')"
                @change="selectYear"
                class="mb-1"
                hide-details
                outlined
              )
              v-select(
                v-model="fastRange"
                :items="fastRangeItems"
                @change="selectFastDate"
                :label="$t('Select period')"
                clearable
                hide-details
                outlined
              )
      v-card-actions(class="text-right")
        v-col(cols="12" class="py-0")
          v-row(justify="end")
            v-btn(color="primary" class="ml-2" @click="onChoose") {{ $t('choose') }}

</template>

<script>

import { formats } from '~/const/global'
import ESvgIcon from '@/components/elements/icons/e-svg-icon'

export default {
  components: { ESvgIcon },
  props: {
    value: {
      type: Array,
      default: () => []
    },
    type: {
      type: String,
      default: 'date',
      validator: (value) => {
        return ['date', 'datetime', 'time'].includes(value)
      }
    },
    clearable: {
      type: Boolean,
      default: true
    },
    closeOnClick: {
      type: Boolean,
      default: true
    },
    emmitOnlyOnSelectClicked: {
      type: Boolean,
      default: false
    },
    fastDates: {
      type: Boolean,
      default: false
    },
    fastDatesYearSelect: {
      type: Boolean,
      default: false
    },
    filterFastDates: {
      type: Function,
      default: null
    },
    prependIcon: {
      type: Boolean,
      default: false
    },
    chooseOnClose: {
      type: Boolean,
      default: false
    },
    defaultFastRange: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    dateRange: [],
    menu: false,
    fastRange: null,
    year: null,
    datePickerKey: 0
  }),
  computed: {
    min () {
      return this._.isFunction(this.$attrs.min) ? this.$attrs.min(this.dateRange) : this.$attrs.min
    },
    max () {
      return this._.isFunction(this.$attrs.max) ? this.$attrs.max(this.dateRange) : this.$attrs.max
    },
    getFieldFormat () {
      return this.dateRange.map(item => this.$moment(item).format(formats.date)).join(' - ')
    },
    years () {
      const date = new Date()
      const currentYear = date.getFullYear()
      const years = []
      for (let i = currentYear; i >= 2020; i--) {
        years.push(i)
      }
      return years
    },
    fastRangeKeys () {
      return {
        today: 'today',
        yesterday: 'yesterday',
        currentWeek: 'currentWeek',
        currentMonth: 'currentMonth',
        firstQuarter: 'firstQuarter',
        secondQuarter: 'secondQuarter',
        thirdQuarter: 'thirdQuarter',
        fourthQuarter: 'fourthQuarter',
        currentQuarter: 'currentQuarter',
        currentYear: 'currentYear'
      }
    },
    fastRangeItems () {
      const items = [
        { text: this.$t('Today'), value: this.fastRangeKeys.today },
        { text: this.$t('Yesterday'), value: this.fastRangeKeys.yesterday },
        { text: this.$t('Current week'), value: this.fastRangeKeys.currentWeek },
        { text: this.$t('Current month'), value: this.fastRangeKeys.currentMonth },
        { text: this.$t('_num quarter', { number: 1 }), value: this.fastRangeKeys.firstQuarter },
        { text: this.$t('_num quarter', { number: 2 }), value: this.fastRangeKeys.secondQuarter },
        { text: this.$t('_num quarter', { number: 3 }), value: this.fastRangeKeys.thirdQuarter },
        { text: this.$t('_num quarter', { number: 4 }), value: this.fastRangeKeys.fourthQuarter },
        { text: this.$t('Current quarter'), value: this.fastRangeKeys.currentQuarter },
        { text: this.$t('Current year'), value: this.fastRangeKeys.currentYear }
      ]
      return this._.isFunction(this.filterFastDates) ? this.filterFastDates(items) : items
    }
  },
  watch: {
    dateRange (val) {
      let value = val
      if (this._.get(val, '[0]') === this._.get(val, '[1]')) {
        value = [this._.get(val, '[0]')]
      }
      this.$emit('input', value)
    }
  },
  created () {
    this.setCurrentYear()
    if (Array.isArray(this.value)) {
      this.dateRange = this.value
    }
    if (this.defaultFastRange && Object.values(this.fastRangeKeys).includes(this.defaultFastRange)) {
      this.fastRange = this.defaultFastRange
    }
  },
  methods: {
    setCurrentYear () {
      const date = new Date()
      this.year = date.getFullYear()
    },
    selectYear () {
      this.selectFastDate(this.fastRange)
    },
    selectFastDate (val) {
      let dateStart = new Date()
      let dateEnd = new Date()

      dateStart.setFullYear(this.year)
      dateEnd.setFullYear(this.year)

      if (val === this.fastRangeKeys.yesterday) {
        dateStart.setDate(dateStart.getDate() - 1)
        dateEnd.setDate(dateEnd.getDate() - 1)
      } else if (val === this.fastRangeKeys.currentWeek) {
        dateStart = this.$moment(dateStart).startOf('isoWeek').toDate()
        dateEnd = this.$moment(dateEnd).endOf('isoWeek').toDate()
      } else if (val === this.fastRangeKeys.currentMonth) {
        dateStart = this.$moment(dateStart).startOf('month').toDate()
        dateEnd = this.$moment(dateEnd).endOf('month').toDate()
      } else if (val === this.fastRangeKeys.firstQuarter) {
        dateStart.setMonth(0)
        dateStart.setDate(1)
        dateEnd.setMonth(2)
        dateEnd.setDate(31)
      } else if (val === this.fastRangeKeys.secondQuarter) {
        dateStart.setMonth(3)
        dateStart.setDate(1)
        dateEnd.setMonth(5)
        dateEnd.setDate(30)
      } else if (val === this.fastRangeKeys.thirdQuarter) {
        dateStart.setMonth(6)
        dateStart.setDate(1)
        dateEnd.setMonth(8)
        dateEnd.setDate(30)
      } else if (val === this.fastRangeKeys.fourthQuarter) {
        dateStart.setMonth(9)
        dateStart.setDate(1)
        dateEnd.setMonth(11)
        dateEnd.setDate(31)
      } else if (val === this.fastRangeKeys.currentQuarter) {
        const currentMonth = dateStart.getMonth()
        if (currentMonth === 0 || currentMonth === 1 || currentMonth === 2) {
          return this.selectFastDate(this.fastRangeKeys.firstQuarter)
        } else if (currentMonth === 3 || currentMonth === 4 || currentMonth === 5) {
          return this.selectFastDate(this.fastRangeKeys.secondQuarter)
        } else if (currentMonth === 6 || currentMonth === 7 || currentMonth === 8) {
          return this.selectFastDate(this.fastRangeKeys.thirdQuarter)
        } else {
          return this.selectFastDate(this.fastRangeKeys.fourthQuarter)
        }
      } else if (val === this.fastRangeKeys.currentYear) {
        dateStart.setMonth(0)
        dateStart.setDate(1)
        dateEnd.setMonth(11)
        dateEnd.setDate(31)
      }

      if (this.max) {
        if (new Date(dateEnd) > new Date(this.max)) {
          dateEnd = this.max
        }
        if (new Date(dateStart) > new Date(this.max)) {
          dateStart = this.max
        }
      }

      if (this.min) {
        if (new Date(dateEnd) < new Date(this.min)) {
          dateEnd = this.min
        }
        if (new Date(dateStart) < new Date(this.min)) {
          dateStart = this.min
        }
      }

      this.dateRange = [
        this.$moment(dateStart).format(formats.dateISO8601),
        this.$moment(dateEnd).format(formats.dateISO8601)
      ]
      this.datePickerKey += 1
    },
    dateChange (val) {
      this.fastRange = null
      if (!this.emmitOnlyOnSelectClicked) {
        this.$emit('input', val)
      }
    },
    now () {
      return new this.$moment()
    },
    onMenuInput (val) {
      if (!val && this.chooseOnClose) {
        this.onChoose()
      }
    },
    onChoose () {
      this.menu = false
      // this need because there are some issue when select date range in v-date-picker
      if (this.dateRange.length === 2) {
        if (this.$moment(this.dateRange[0]).isAfter(this.dateRange[1])) {
          this.dateRange.reverse()
        }
      }
      this.$emit('input', this.dateRange)
      this.$emit('choose', this.dateRange)
    },
    clear () {
      this.dateRange = []
      this.$emit('input', this.dateRange)
    },
    titleDate (dates) {
      if (!dates || !dates.length) {
        return '-'
      } else if (dates.length === 1) {
        return this.$moment(dates[0]).format(formats.date)
      } else if (dates.length === 2) {
        const sortedDates = dates.concat().sort()
        return this.$moment(sortedDates[0]).format(formats.date) + ' - ' + this.$moment(sortedDates[1]).format(formats.date)
      }
    }
  }
}
</script>

<style lang="scss">
.e-input-datetime {
  &__card {
    .v-picker {
      border-radius: 0 !important;

      &__title {
        height: auto;
      }

      .v-date-picker-title__date {
        font-size: 1.25rem;
      }
    }
  }

  &__select {
    max-width: 280px;
    display: block;
    margin: 0 auto;
  }
}
</style>
