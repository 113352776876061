import { VDivider } from 'vuetify/lib/components/VDivider';
import { VStepper } from 'vuetify/lib/components/VStepper';
import { VStepperContent } from 'vuetify/lib/components/VStepper';
import { VStepperHeader } from 'vuetify/lib/components/VStepper';
import { VStepperItems } from 'vuetify/lib/components/VStepper';
import { VStepperStep } from 'vuetify/lib/components/VStepper';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VStepper,_vm._b({on:{"change":_vm.onChange},model:{value:(_vm.step),callback:function ($$v) {_vm.step=$$v},expression:"step"}},'v-stepper',_vm.stepper.stepperAttrs,false),[_c(VStepperHeader,[_vm._l((_vm.stepper.steps),function(n){return [_c(VStepperStep,_vm._b({key:(n + "-step"),attrs:{"complete":_vm.step > n,"step":n,"rules":_vm.stepRules(n)}},'v-stepper-step',_vm.stepper.stepAttrs,false),[_vm._v(_vm._s(_vm.$t(_vm.generateText(n))))]),(n !== _vm.stepper.steps && _vm.stepper.stepperAttrs.divider)?_c(VDivider,{key:n}):_vm._e()]})],2),_c(VStepperItems,_vm._l((_vm.stepper.steps),function(n){return _c(VStepperContent,{key:(n + "-content"),attrs:{"step":n}},[_c(_vm.stepper.stepComponents[n-1].component,_vm._g(_vm._b({tag:"component",attrs:{"stepperData":_vm.dataBag,"step":_vm.step},on:{"update:stepperData":function($event){_vm.dataBag=$event},"update:stepper-data":function($event){_vm.dataBag=$event},"update:step":function($event){_vm.step=$event}}},'component',Object.assign({}, _vm.stepper.stepComponents[n-1].attrs),false),_vm.$listeners))],1)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }