const validate = {
  methods: {
    async validate () {
      return await this.$refs[this.model.entity].validate()
    },
    performInvalidFields (fieldsRefs) {
      fieldsRefs[Object.keys(fieldsRefs)[0]].$el.scrollIntoView()
    },
    wrapQuotesProviderName (name) {
      return `"${name}"`
    }

  }
}

export default validate
