import { mapState } from 'vuex'

const taxAuth = {
  computed: {
    ...mapState({
      iitDialog: state => state.dialogs.dialogs.edsIitCheckboxDialog
    })
  },
  methods: {
    destroyIitGlobalDialog (store) {
      const $store = this.$store || store
      $store.commit('user/SET_KEY_DATA', {
        keyData: null
      })
      $store.commit('dialogs/SET_DIALOG_OPTIONS', {
        type: 'edsIitCheckboxDialog',
        options: {
          open: false,
          visible: false,
          keysMatchCheck: true,
          model: null,
          loading: false
        },
        actions: {
          onKeyLoaded: null
        }
      })
    }
  }
}

export default taxAuth
