import Vue from 'vue'
import { contexts } from '~/const/global'
import authUser from '~/modules/auth/authUser'
import currentOrganizationService from '~/modules/auth/services/currentOrganization'

Vue.mixin({
  computed: {
    $localStorageClientData () {
      if (!localStorage.getItem('clientData')) {
        localStorage.setItem('clientData', JSON.stringify({}))
      }
      return localStorage.getItem('clientData') && JSON.parse(localStorage.getItem('clientData'))
    },
    $config () {
      const modelEntity = this._.get(this.model, 'entity')
      const storageItemsPerPage = this._.get(this.$localStorageClientData, `itemsPerPage.${modelEntity}`)

      return {
        locale: this.$i18n.locales.find(locale => locale.code === this.$i18n.locale),
        customSort: items => items,
        contexts,
        table: {
          'items-per-page': storageItemsPerPage || 50,
          'no-results-text': this.$t('There are no documents'),
          'footer-props': {
            'items-per-page-text': this.$t('Rows per page'),
            'items-per-page-all-text': this.$t('All'),
            'page-text': this.$t('{0}-{1} of {2}'),
            'items-per-page-options': [10, 15, 20, 50, 100],
            'show-first-last-page': true,
            'show-current-page': true
          }
        }
      }
    },
    $User () {
      return authUser.get()
    },
    $Organization () {
      return authUser.get() ? currentOrganizationService.getCurrentOrganization() : null
    }
  },
  methods: {
    $setLocalStorageClientData (data) {
      localStorage.setItem('clientData', JSON.stringify({
        ...this.$localStorageClientData,
        ...data
      }))
    }
  }
})
