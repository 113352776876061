import { Model } from '@vuex-orm/core'
import { get, find } from 'lodash'
import OrmModel from '~/models/abstracts/base/OrmModel'
import ChainInheritance from '~/models/mixins/ChainInheritance'
import PersistAble from '~/models/mixins/PersistAble'

export default class OrganizationUsers extends ChainInheritance(OrmModel, [PersistAble]) {
  static entity = 'organizationusers'
  static paginated = true

  static statuses = [
    {
      text: 'Активний',
      value: 'active'
    },
    {
      text: 'Очікується',
      value: 'awaiting'
    }
  ]

  static ROLES = {
    ACCOUNTANT: 'ROLE_ACCOUNTANT',
    OWNER: 'ROLE_OWNER',
    ADMINISTRATOR: 'ROLE_ADMINISTRATOR'
  }

  static userRoles = [
    {
      text: 'Бухгалтер',
      value: 'ROLE_ACCOUNTANT'
    },
    {
      text: 'Адміністратор',
      value: 'ROLE_ADMINISTRATOR'
    },
    {
      text: 'Головний бухгалтер',
      value: 'ROLE_HEAD_ACCOUNTANT'
    }
  ]

  static userRolesReadOnly = [
    {
      text: 'Бухгалтер',
      value: 'ROLE_ACCOUNTANT'
    },
    {
      text: 'Адміністратор',
      value: 'ROLE_ADMINISTRATOR'
    },
    {
      text: 'Власник',
      value: 'ROLE_OWNER'
    },
    {
      text: 'Головний бухгалтер',
      value: 'ROLE_HEAD_ACCOUNTANT'
    }
  ]

  static fields () {
    return {
      id: this.attr(null),
      role: this.attr(null),
      user: this.attr(null),
      status: this.attr(null),
      notify: this.attr(null)
    }
  }

  get handleName () {
    return get(this.user, 'name') || '-'
  }

  get handleEmail () {
    return get(this.user, 'email') || '-'
  }

  get handleRole () {
    return get(find(OrganizationUsers.userRolesReadOnly, item => item.value === this.role), 'text')
  }

  static ormHeaders = [
    { text: 'First name', value: 'handleName', sortable: false, filterable: false },
    { text: 'Email', value: 'handleEmail', sortable: false, filterable: false },
    { text: 'Role', value: 'handleRole', sortable: false, filterable: false },
    { text: 'Status', value: 'status', sortable: false, filterable: false },
    { text: 'Actions', align: 'right', value: 'actions', width: '80', sortable: false, filterable: false }
  ]

  static apiConfig = {
    get actions () {
      const configActions = Object.assign({}, Model.apiConfig.actions)

      configActions.getOrganizationUsersById = function (id) {
        return this.get(Model.$routes.organizationusers.users(id), { dataKey: null })
      }

      configActions.accept = function (id) {
        return this.post(Model.$routes.organizationusers.accept(id), {}, { save: false })
      }

      configActions.notify = function (id, payload) {
        return this.put(Model.$routes.organizationusers.notify(id), payload, { save: false })
      }

      return configActions
    }
  }
}
