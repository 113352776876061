import { Model } from '@vuex-orm/core'
import OrmModel from '~/models/abstracts/base/OrmModel'
import ChainInheritance from '~/models/mixins/ChainInheritance'
import PersistAble from '~/models/mixins/PersistAble'

export default class Document extends ChainInheritance(OrmModel, [PersistAble]) {
  static entity = 'document'
  static paginated = true
  static defaultSortParam = 'createdAt'

  // todo: update statuses
  static statuses = [
    {
      text: 'Завантажений',
      value: 'new'
    },
    {
      text: 'Підписано',
      value: 'signed'
    },
    {
      text: 'Погоджено',
      value: 'approved'
    },
    {
      text: 'Відхилено',
      value: 'rejected'
    },
    {
      text: 'Надісланий на підпис',
      value: 'sent_for_sign'
    },
    {
      text: 'Надісланий на погодження',
      value: 'sent_for_approve'
    },
    {
      text: 'Очікує вашого підпису',
      value: 'waiting_for_your_sign'
    },
    {
      text: 'Очікує підпису контрагента(ів)',
      value: 'waiting_for_contractor_sign'
    },
    {
      text: 'Очікує на погодження',
      value: 'waiting_for_approve'
    },
    {
      text: 'Переглянуто',
      value: 'viewed'
    },
    {
      text: 'Очікує перегляду',
      value: 'waiting_for_view'
    },
    {
      text: 'Заповнено',
      value: 'filled'
    },
    {
      text: 'Надіслано на заповнення',
      value: 'sent_for_fill'
    },
    {
      text: 'Очікує заповнення',
      value: 'waiting_for_fill'
    }
  ]

  // todo: update statuses
  static DOCUMENT_STATUSES = {
    new: 'new',
    signed: 'signed',
    approved: 'approved',
    rejected: 'rejected',
    sentForSign: 'sent_for_sign',
    sentForApprove: 'sent_for_approve',
    waitingForYourSign: 'waiting_for_your_sign',
    waitingForContractorSign: 'waiting_for_contractor_sign',
    waitingForApprove: 'waiting_for_approve',
    waitingForView: 'waiting_for_view',
    viewed: 'viewed',
    filled: 'filled',
    sentForFill: 'sent_for_fill',
    waitingForFill: 'waiting_for_fill'
  }

  static USER_ROLES = {
    owner: 'ROLE_OWNER',
    signer: 'DOCUMENT_SIGNER',
    approver: 'DOCUMENT_APPROVER',
    viewer: 'DOCUMENT_VIEWER',
    filler: 'DOCUMENT_FILLER'
  }

  static fields () {
    return {
      id: this.attr(null),
      createdAt: this.attr(null),
      status: this.attr(null),
      title: this.attr(null),
      hasPublicAccess: this.attr(null),
      hasParticipants: this.attr(null),
      archived: this.attr(null),
      file: this.attr(null),
      fileExtension: this.attr(null),
      downloadLink: this.attr(null),
      currentUser: this.attr(null),
      tags: this.attr(null),
      canDelete: this.attr(null),
      fields: this.attr(null),
      canFillFields: this.attr(null),
      canSend: this.attr(null)
    }
  }

  get handleDate () {
    return this.getDate(this.createdAt)
  }

  static ormHeaders = [
    { text: 'Name', value: 'title', sortable: true, filterable: true },
    { text: 'Date uploading', value: 'handleDate', sortable: true, filterable: true, sortQuery: 'createdAt' },
    { text: 'Status', value: 'status', sortable: false, filterable: false },
    { text: 'Actions', align: 'center', value: 'actions', width: '120', sortable: false, filterable: false }
  ]

  static apiConfig = {
    get actions () {
      const configActions = Object.assign({}, Model.apiConfig.actions)

      configActions.sign = function (id, payload) {
        return this.post(Model.$routes.document.sign(id), payload, { save: false })
      }
      configActions.send = function (id) {
        return this.post(Model.$routes.document.send(id), {}, { save: false })
      }
      configActions.download = function (id, query) {
        return this.get(Model.$routes.document.download(id), {
          responseType: 'blob',
          save: false,
          params: query
        })
      }
      configActions.toArchive = function (id) {
        return this.post(Model.$routes.document.toArchive(id), {}, { save: false })
      }
      configActions.fromArchive = function (id) {
        return this.post(Model.$routes.document.fromArchive(id), {}, { save: false })
      }
      configActions.getParticipants = function (id) {
        return this.get(Model.$routes.document.participants(id), { save: false })
      }
      configActions.sendParticipants = function (id, payload, query) {
        return this.post(Model.$routes.document.participants(id), payload, {
          save: false,
          params: query
        })
      }
      configActions.generateDocumentLink = function (id, payload) {
        return this.post(Model.$routes.document.documentLink(id), payload, { save: false })
      }
      configActions.deleteDocumentLink = function (id) {
        return this.delete(Model.$routes.document.documentLink(id), { save: false })
      }
      configActions.downloadDocuments = function (payload) {
        return this.post(Model.$routes.document.downloadDocuments(), payload, { save: false })
      }
      configActions.checkStatus = function (id) {
        return this.get(Model.$routes.document.downloadStatus(id), { save: false })
      }
      configActions.downloadArchive = function (id) {
        return this.get(Model.$routes.document.downloadArchive(id), {
          responseType: 'blob',
          save: false
        })
      }
      configActions.addTags = function (id, payload) {
        return this.post(Model.$routes.document.tags(id), payload, { save: false })
      }
      configActions.deleteTag = function (id, tagId) {
        return this.delete(Model.$routes.document.concreteTag(id, tagId), { save: false })
      }
      configActions.attachTags = function (payload) {
        return this.post(Model.$routes.document.attachTags(), payload, { save: false })
      }
      configActions.fillDynamicFields = function (id, payload) {
        return this.post(Model.$routes.document.fillDynamicFields(id), payload, { save: false })
      }
      configActions.shareDocuments = function (payload) {
        return this.post(Model.$routes.document.documentsParticipants(), payload, { save: false })
      }
      return configActions
    }
  }
}
