const participants = {
  computed: {
    participantActions () {
      return [
        {
          text: this.$t('To view'),
          value: 'view'
        },
        {
          text: this.$t('To sign'),
          value: 'sign'
        },
        {
          text: this.$t('To fill'),
          value: 'fill'
        }
      ]
    }
  }
}

export default participants
