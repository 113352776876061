import { Model } from '@vuex-orm/core'

export default class AccessToken extends Model {
  static entity = 'accesstoken'
  static paginated = true
  static defaultSortParam = 'createdAt'

  static fields () {
    return {
      id: this.attr(null),
      title: this.attr(null),
      createdAt: this.attr(null),
      accessToken: this.attr(null)
    }
  }

  static apiConfig = {
    get actions () {
      const configActions = Object.assign({}, Model.apiConfig.actions)
      return configActions
    }
  }
}
