<template lang="pug">
  v-form(class="organizations-users-add")
    h2(class="title-32px mb-2") {{ $t('New users') }}
    p(class="text-14px") {{ $t('Enter your colleagues` email and assign them a role, and we`ll send a message telling them what to do next. You can always change access in the settings section') }}
    ValidationObserver(ref="form")
      div(
        v-for="(user, idx) in users"
        :key="idx"
        class="organizations-users-add__item"
        :class="{ 'with-delete': users.length > 1 }"
      )
        ValidationProvider(:name="$t('Email')" rules="required|email" v-slot="{ errors }" mode="lazy")
          v-text-field(
            v-model="users[idx].email"
            :label="$t('Email')"
            :error-messages="errors"
            filled
          )
        ValidationProvider(:name="$t('Role in system')" rules="required" v-slot="{ errors }" mode="lazy")
          v-select(
            v-model="users[idx].role"
            :items="userRoles"
            :label="$t('Role in system')"
            :error-messages="errors"
            :menuProps="{ bottom: true, offsetY: true }"
            filled
          )
        v-btn(
          v-if="users && users.length > 1"
          class="organizations-users-add__item-delete"
          height="48"
          color="#FF17440F"
          @click="deleteUser(idx)"
        )
          e-svg-icon danger-trash
      div(class="d-flex align-center pb-8")
        v-switch(
          v-model="notifyUsers"
          class="mt-0 mr-2"
          hide-details
        )
        span(class="text-14px dark pt-1") {{ $t('Send emails about documents') }}
    div(class="organizations-users-add__actions")
      v-btn(
        class="secondary-button"
        @click="addUser"
      ) {{ $t('Add one more user') }}
      v-btn(
        :loading="isLoading"
        class="main-button"
        @click="submit"
      ) {{ $t('Send messages') }}
</template>

<script>
import { ValidationProvider } from 'vee-validate'
import OrganizationUsers from '~/modules/organizations/models/OrganizationUsers'
import responsive from '~/mixins/pages/responsive'
import ELink from '~/components/elements/links/e-link'
import ESvgIcon from '~/components/elements/icons/e-svg-icon'

export default {
  name: 'FormOrganizationsUsersAdd',
  components: {
    ValidationProvider,
    ELink,
    ESvgIcon
  },
  mixins: [responsive],
  props: {
    closeModal: {
      type: Function,
      default: () => {}
    }
  },
  data () {
    return {
      users: [{
        email: null,
        role: null
      }],
      isLoading: false,
      notifyUsers: true
    }
  },
  computed: {
    userRoles () {
      return OrganizationUsers.userRoles.filter(item => item.value !== OrganizationUsers.ROLES.OWNER)
    }
  },
  methods: {
    addUser () {
      this.users.push({
        email: null,
        role: null
      })
    },
    deleteUser (userIdx) {
      this.users.splice(userIdx, 1)
    },
    async submit () {
      const valid = await this.$refs.form.validate()
      if (!valid) {
        return
      }
      try {
        this.isLoading = true
        for (const { email, role } of this.users) {
          const payload = {
            email,
            role,
            organizationId: this.$route.params.id,
            notify: this.notifyUsers
          }
          await OrganizationUsers.api().create(payload)
        }
        this.closeModal(true)
      } catch (e) {
        this.closeModal(false)
        this.$handlers.error(e, this)
      } finally {
        this.isLoading = false
      }
    }
  }
}
</script>

<style lang="scss">
.organizations-users-add {
  &__item {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;

    &.with-delete {
      grid-template-columns: 1fr 1fr 48px;
    }
  }
  &__actions {
    display: grid;
    grid-template-rows: 1fr 1fr;
    gap: 16px;

    @media (min-width: map-get($breakpoints, 'md')) {
      grid-template-columns: 1fr 1fr;
      grid-template-rows: none;
    }
  }
}
</style>
