import { Model } from '@vuex-orm/core'

class Guideline extends Model {
  static entity = '_guideline'

  static fields () {
    return {
      id: this.uid(),
      isVisible: this.attr(false),
      type: this.attr(null),
      params: this.attr(null)
    }
  }

  async show (params) {
    this.isVisible = true
    this.params = params
    await this.$save()
  }

  async hide () {
    this.isVisible = false
    await this.$save()
  }

  async close () {
    this.isVisible = false
    this.params = null
    await this.$save()
  }
}

export default Guideline
