<template>
  <svg-icon :name="nameIcon" :class="classes" v-on="$listeners" />
</template>

<script>

const name = 'e-flat-icon'

export default {
  name,
  inheritAttrs: false,
  props: {
    size: {
      type: String,
      default: 'md',
      validator: (value) => {
        return ['xs', 'xsm', 'sm', 'xmd', 'md', 'lg', 'xlg', 'xxlg', 'xxxlg', 'bg', 'xbg', 'auto'].includes(value)
      }
    },
    name: {
      type: String,
      default: ''
    }
  },
  computed: {
    classes () {
      return ['svg-icon', this.size].concat(' ')
    },
    nameIcon () {
      if (this.name) {
        return this.name
      } else {
        return this._.get(this.$slots, 'default[0].text', '').trim()
      }
    }
  }
}
</script>

<style lang="sass">
  .svg-icon
    &.auto
      max-height: none
      max-width: none
    &.xs
      max-height: 12px
      max-width: 12px
    &.xsm
      max-height: 14px
      max-width: 14px
    &.sm
      max-height: 16px
      max-width: 16px
    &.xmd
      max-height: 18px
      max-width: 18px
    &.md
      max-height: 20px
      max-width: 20px
    &.lg
      max-height: 24px
      max-width: 24px
    &.xlg
      max-height: 30px
      max-width: 30px
    &.xxlg
      max-height: 40px
      max-width: 40px
    &.xxxlg
      max-height: 50px
      max-width: 50px
    &.bg
      max-height: 65px
      max-width: 65px
    &.xbg
      max-height: 80px
      max-width: 80px
</style>
