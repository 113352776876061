import { VBtn } from 'vuetify/lib/components/VBtn';
import { VForm } from 'vuetify/lib/components/VForm';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VForm,{staticClass:"organizations-users-edit"},[_c('h2',{staticClass:"title-32px mb-2"},[_vm._v(_vm._s(_vm.$t('Change user role')))]),_c('p',{staticClass:"text-14px mb-8"},[_vm._v(_vm._s(_vm.$t('You are going to change the role of the selected user in the system. The selected role will determine the level of access and user capabilities in the system')))]),_c('ValidationObserver',{ref:"form"},[_c('ValidationProvider',{attrs:{"name":_vm.$t('Email'),"rules":"required|email","mode":"lazy"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c(VTextField,{attrs:{"label":_vm.$t('Email'),"error-messages":errors,"readonly":"","filled":""},model:{value:(_vm.dataForm.email),callback:function ($$v) {_vm.$set(_vm.dataForm, "email", $$v)},expression:"dataForm.email"}})]}}])}),_c('ValidationProvider',{attrs:{"name":_vm.$t('Role in system'),"rules":"required","mode":"lazy"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c(VSelect,{attrs:{"items":_vm.userRoles,"label":_vm.$t('Role in system'),"error-messages":errors,"menuProps":{ bottom: true, offsetY: true },"filled":""},model:{value:(_vm.dataForm.role),callback:function ($$v) {_vm.$set(_vm.dataForm, "role", $$v)},expression:"dataForm.role"}})]}}])})],1),_c('div',{staticClass:"organizations-users-edit__actions"},[_c(VBtn,{attrs:{"height":"48"},on:{"click":function($event){return _vm.closeModal(false)}}},[_vm._v(_vm._s(_vm.$t('Cancel')))]),_c(VBtn,{staticClass:"main-button",attrs:{"loading":_vm.isLoading},on:{"click":_vm.submit}},[_vm._v(_vm._s(_vm.$t('Save')))])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }