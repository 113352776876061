const filtration = {
  data: _ => ({
    filters: {},
    filtersLoading: null
  }),
  async created () {
    if (this.model.isForceFiltered) {
      // TODO: make it static.
      const cfg = this.model.getForceFilter()

      // TODO: create one "unary" source of loading state.
      this.tableLoading = true
      await cfg.model.api().all()
      this.filtersLoading = true
      this.tableLoading = false

      const items = cfg.model.all()

      this.filters.type = this._.isFunction(cfg.diff) ? cfg.diff(items) : cfg.inter(items)
    }
  },
  computed: {
    isAsyncFiltersLoaded () {
      return typeof this.filtersLoading === 'boolean' && this.filtersLoading
    },

    getFiltrationFields () {
      const entries = Object.entries(this.filters).map(([key, v]) => {
        if (this._.isObject(v) && this._.isNull(v.value)) {
          return [key, undefined]
        }

        // Trimming spaces in search model
        if (key === 'search' && v) {
          return [key, v.trim()]
        }

        return [key, v]
      })
      return Object.assign(
        {},
        Object.fromEntries(this._.compact(entries)),
        this.defaultFilter
      )
    },

    getServerFilters () {
      return this.getFiltrationFields
    }
  },
  methods: {
    getLocalFilters () {
      if (this.model.paginated) {
        // TODO need to test on regressive bugs
        // if we have server side pagination we don`t need to use frontend filtration
        return {}
      }

      return this.getFiltrationFields
    }
  }
}

export default filtration
