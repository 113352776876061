import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.tooltip)?_c(VTooltip,{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g({staticStyle:{"width":"23px"}},on),[_c(VRadioGroup,{staticClass:"ma-0 pa-0",attrs:{"value":_vm.boolValue,"disabled":!_vm.boolValue,"color":_vm.color,"hide-details":""}},[_c(VRadio,{attrs:{"value":true,"color":_vm.color,"ripple":false,"readonly":""}})],1)],1)]}}],null,false,3330773315)},[_c('span',[_vm._v(_vm._s(_vm.$t(_vm.tooltip)))])]):_c(VRadioGroup,{staticClass:"ma-0 pa-0",attrs:{"value":_vm.boolValue,"disabled":!_vm.boolValue,"color":_vm.color,"hide-details":""}},[_c(VRadio,{attrs:{"value":true,"color":_vm.color,"ripple":false,"readonly":""}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }