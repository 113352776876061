<template lang="pug">
  t-dialog(v-bind="$attrs" v-on="listeners" type="confirmation" max-width="495px" )
    template(#title)
      span(class="text-center d-inline-block") {{ title }}

    template(#actions)
      t-orm-buttons(:items="ormButtons" :classes="['text-center']")
</template>

<script>
import TDialog from '~/components/templates/t-dialog'
import EFlatIcon from '~/components/elements/icons/e-flat-icon'
import TOrmButtons from '~/components/templates/orm/t-orm-buttons'

export default {
  components: {
    TDialog,
    EFlatIcon,
    TOrmButtons
  },
  props: {
    type: {
      type: String,
      required: true
    },
    model: {
      type: Function,
      required: true
    }
  },
  data: () => ({
    title: null,
    item: {},
    localLoading: false
  }),
  computed: {
    dialogConfig () {
      return this.model.getOrmDialogsConfig(this.type)
    },
    listeners () {
      const vm = this
      return Object.assign({},
        this.$listeners,
        {
          input (event) {
            vm.$emit('input', event)
            vm.clear()
          }
        }
      )
    },
    ormButtons () {
      return [
        {
          text: 'Cancel',
          attrs: {
            color: 'primary',
            depressed: true,
            text: true
          },
          call: this.close
        },
        {
          text: 'Confirm',
          attrs: {
            color: 'error',
            depressed: true,
            class: ['mr-4']
          },
          loading: this.localLoading,
          call: this.delete
        }
      ]
    }
  },
  methods: {
    clear (immediate = false) {
      immediate ? this.item = {} : setTimeout(() => {
        this.item = {}
      }, 300)
    },

    close () {
      this.$emit('input', false)
      this.clear()
    },

    async delete () {
      this.localLoading = true
      try {
        const confirmCallback = this._.get(this.dialogConfig, 'confirmCallback', null)

        if (typeof confirmCallback === 'function') {
          await confirmCallback(this.item)
        } else {
          await this.model.api().update(this.item, { active: true }, false)
        }

        const config = this.dialogConfig.notification(this.item)
        const typeArr = config.type.split('|')
        const isType = (typeArr.length >= 2)
        config.type = (isType) ? this.$tc(typeArr[0], typeArr[1]) : this.$tc(config.type, 1)

        this.$notification.success(this.$t('m-orm-activate-dialog.notification', config))
        this.close()
      } catch (e) {
        this.$handlers.error(e, this)
      }
      this.localLoading = false
    },

    fill (item, title) {
      this.item = item
      this.title = title
    }
  }
}
</script>

<style lang="sass">
</style>
