import Document from '~/modules/documents/models/Document'
import DocumentSignatures from '~/modules/documents/models/DocumentSignatures'

const documentUpdate = {
  methods: {
    async fetchDocument (id) {
      try {
        return await Document.api().read(id)
      } catch (e) {
        // TODO: check if document exist
        this.$router.push({ name: 'auth', query: { fromDocumentId: id } })
        this.$handlers.error(e, this)
      }
    },
    async fetchSignatures (documentId) {
      try {
        return await DocumentSignatures.api().read(documentId)
      } catch (e) {
        this.$handlers.error(e, this)
      }
    }
  }
}

export default documentUpdate
