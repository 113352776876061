<template lang="pug">
  v-btn(class="button-burger" icon @click="$emit('click')")
    div(class="burger-menu" :class="{'active': active}")
      span(v-for="line in 3" :key="line" :style="burgerStyles")
</template>
<script>
export default {
  name: 'EButtonBurger',
  props: {
    active: {
      type: Boolean,
      required: true
    },
    color: {
      type: String,
      default: '#ffffff'
    }
  },
  computed: {
    burgerStyles () {
      return {
        backgroundColor: this.color
      }
    }
  }
}
</script>

<style scoped lang="scss">
.button-burger {
  width: 40px;
  height: 40px;

  background: $primary;
  border-radius: 8px;

  display: flex;
  align-items: center;
  justify-content: center;
}
.burger-menu {
  margin: 20px auto;
  width: 20px;
  height: 14px;
  cursor: pointer;
  position: relative;
  background: none !important;
}
.burger-menu span {
  height: 2px;
  position: absolute;
  width: 100%;
  left: 0;
  transition: all 0.3s ease;
}
.burger-menu span:first-child {
  top: 0;
}
.burger-menu span:nth-child(2) {
  top: 6px;
}
.burger-menu span:last-child {
  width: 70%;
  top: 12px;
}
.burger-menu.active span:nth-child(2) {
  opacity: 0;
}
.burger-menu.active span:first-child,
.burger-menu.active span:last-child {
  top: 6px;
}
.burger-menu.active span:first-child {
  transform: rotate(45deg);
}
.burger-menu.active span:last-child {
  width: 100%;
  transform: rotate(-45deg);
}
</style>
