<template lang="pug">
  div(
    v-if="isVisible"
    class="introduction-guideline"
  )
    v-btn(
      x-small
      icon
      class="introduction-guideline__close-btn"
      @click="close"
    )
      e-svg-icon(size="sm") close
    div(class="introduction-guideline__image" :class="imageClass")
      transition(name="slide-left" mode="out-in")
        img(:src="imagePath" alt="guideline-image" :key="imagePath")
    div(class="introduction-guideline__content")
      div
        transition(name="fade" mode="out-in")
          h4(class="text-16px dark fw-700" :key="title") {{ title }}
        transition(name="fade" mode="out-in")
          p(class="text-14px" :key="text") {{ text }}
      div(class="introduction-guideline__bottom")
        div(class="introduction-guideline__progress")
          v-progress-linear(
            rounded
            height="5"
            :value="progressValue"
            color="#8F257D"
            :key="progressValue"
            background-color="#121212"
            background-opacity="0.06"
          )
        div(class="introduction-guideline__actions")
          transition(name="fade" mode="out-in")
            v-btn(
              v-if="showBackButton"
              text
              height="32"
              class="introduction-guideline__action"
              :key="showBackButton"
              @click="goBack"
            ) {{ $t('Go back') }}
          transition(name="fade" mode="out-in")
            v-btn(
              class="main-button xs"
              :key="nextButtonText"
              @click="goNext"
            ) {{ nextButtonText }}
</template>

<script>
import Guideline from '~/modules/guideline/models/Guideline'
import ESvgIcon from '~/components/elements/icons/e-svg-icon'

export default {
  name: 'IntroductionGuideline',
  components: {
    ESvgIcon
  },
  props: {
    type: {
      type: String,
      default: 'introduction'
    }
  },
  data () {
    return {
      currentStep: 0
    }
  },
  computed: {
    guideline () {
      return Guideline.query().where('type', this.type).first()
    },
    isVisible () {
      return this._.get(this.guideline, 'isVisible', false)
    },
    params () {
      return this._.get(this.guideline, 'params', {})
    },
    steps () {
      return this._.get(this.params, 'steps', [])
    },
    currentStepData () {
      return this._.get(this.steps, `[${this.currentStep}]`, null)
    },
    imagePath () {
      return this.currentStepData && this._.get(this.currentStepData, 'imagePath')
    },
    imageClass () {
      return this.currentStepData && this._.get(this.currentStepData, 'imageClass')
    },
    title () {
      return this.currentStepData && this._.get(this.currentStepData, 'title')
    },
    text () {
      return this.currentStepData && this._.get(this.currentStepData, 'text')
    },
    progressValue () {
      return (this.currentStep + 1) / this._.get(this.steps, 'length') * 100
    },
    isLastStep () {
      return this.currentStep === this._.get(this.steps, 'length') - 1
    },
    showBackButton () {
      return this.currentStep > 0 && !this.isLastStep
    },
    nextButtonText () {
      return this.currentStepData && this._.get(this.currentStepData, 'nextButtonText', this.$t('Next'))
    },
    onFinish () {
      return this._.get(this.params, 'onFinish', () => {})
    },
    onClose () {
      return this._.get(this.params, 'onClose', () => {})
    }
  },
  created () {
    if (!this.guideline) {
      Guideline.insert({
        data: {
          type: this.type
        }
      })
    }
  },
  methods: {
    close () {
      this.onClose()
      Guideline.update({
        where: this.guideline?.id,
        data: { isVisible: false }
      })
    },
    goBack () {
      this.currentStep--
    },
    goNext () {
      if (this.isLastStep) {
        this.guideline.hide()
        this.onFinish()
      } else {
        this.currentStep++
      }
    }
  }
}
</script>

<style scoped lang="scss">
.introduction-guideline {
  max-width: 280px;
  width: 100%;

  border-radius: 12px;
  background: $light;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  overflow: hidden;

  position: fixed;
  right: 32px;
  bottom: 32px;
  z-index: 1000;

  &__close-btn {
    position: absolute;
    top: 8px;
    right: 8px;
  }

  &__image {
    min-height: 180px;
    height: 100%;

    background: rgba(143, 37, 125, 0.06);
    border-radius: 12px 12px 0 0;

    img {
      max-width: 120%;
      height: auto;
    }
  }

  &__content {
    padding: 12px;
    min-height: 180px;
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__progress {
    width: 75px;
  }
  &__actions {
    display: flex;
    align-items: center;
  }
  &__action {
    font-size: 14px !important;
    font-weight: 500 !important;
  }
}
.slide-left-enter-active, .slide-left-leave-active {
  transition: transform 0.5s;
}
.slide-left-enter {
  transform: translateX(-100%);
}
.slide-left-leave-to {
  transform: translateX(-100%);
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter, .fade-leave-to {
  /* Starting and ending opacity for the transition */
  opacity: 0;
}
</style>
