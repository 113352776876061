import Document from '~/modules/documents/models/Document'

export const state = () => ({
  params: null,
  fileContent: null,
  documentScale: null,
  canvasWidth: null,
  canvasHeight: null
})

export const getters = {
  requestParams: state => state.params,
  scale: state => state.documentScale,
  fileContent: state => state.fileContent,
  canvasWidth: state => state.canvasWidth,
  canvasHeight: state => state.canvasHeight
}

export const mutations = {
  SET_DOCUMENTS_PARAMS (state, value) {
    state.params = value
  },
  SET_DOCUMENT_FILE_CONTENT (state, fileContent) {
    state.fileContent = fileContent
  },
  SET_DOCUMENT_SCALE (state, scale) {
    state.documentScale = scale
  },
  SET_CANVAS_WIDTH (state, width) {
    state.canvasWidth = width
  },
  SET_CANVAS_HEIGHT (state, height) {
    state.canvasHeight = height
  }
}

export const actions = {
  async updateDocumentsWithUpdatedParams ({ state }) {
    return await Document.api().filter(state.params).all()
  }
}
