import authUser from '~/modules/auth/authUser'
import auth from '~/modules/auth/auth'

export default async function ({ route }, force = false, silent = false) {
  const excludedRoutes = ['index', 'auth-login', 'open-id', 'show-id', 'bulk-signature-id', 'widget-id', 'templates-id']
  if (route && excludedRoutes.includes(route.name)) {
    return
  }
  if (auth.getGuestAuthToken() && route.name === 'documents-id') {
    return
  }
  if (auth.isAuthenticated() && (force || !authUser.get())) {
    try {
      await authUser.api.load()
    } catch (e) {
      if (!silent) { throw e }
    }
  }
}
