import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.$User)?_c(VMenu,{attrs:{"offset-y":"","content-class":"profile-menu","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VBtn,_vm._g({staticClass:"profile-button",class:{ 'circled': !_vm.currentOrganization || _vm.xsDevice },attrs:{"icon":!_vm.currentOrganization || _vm.xsDevice,"height":"48"},on:{"click":function($event){_vm.profileMenu = !_vm.profileMenu}}},on),[(_vm.currentOrganization && !_vm.xsDevice)?[_c(VImg,{attrs:{"src":require('~/assets/images/bagcase.png'),"max-width":"16"}}),(_vm.currentOrganizationName)?_c('span',{staticClass:"profile-button__text px-2"},[_vm._v(_vm._s(_vm.currentOrganizationName))]):_vm._e(),_c('span',{staticClass:"profile-button__arrow",class:{ 'active': _vm.profileMenu }},[_c('e-svg-icon',{attrs:{"size":"xsm"}},[_vm._v("arrow-down")])],1)]:[(!_vm.profileMenu)?_c(VImg,{attrs:{"src":require('~/assets/images/person.png'),"max-width":"16"}}):_c(VIcon,[_vm._v("mdi-close")])]],2)]}}],null,false,200223203),model:{value:(_vm.profileMenu),callback:function ($$v) {_vm.profileMenu=$$v},expression:"profileMenu"}},[_c('block-profile-menu-content')],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }