import OrmModel from '~/models/abstracts/base/OrmModel'

export default class OrganizationInvite extends OrmModel {
  static entity = 'organizationinvite'
  static fields () {
    return {
      id: this.attr(null)
    }
  }
}
