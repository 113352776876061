<template lang="pug">
  div()
    div(class="eds-iit-checkbox-key-info")
      h3(
        v-if="title"
        class="eds-iit-checkbox-key-info__title"
      ) {{ title }}
      p(class="eds-iit-checkbox-key-info__subtitle") {{ $t('Organization') }}
      p(class="eds-iit-checkbox-key-info__text") {{ organization }}
      p(class="eds-iit-checkbox-key-info__subtitle") {{ $t('RNOCPP') }}
      p(class="eds-iit-checkbox-key-info__text") {{ code }}
      p(class="eds-iit-checkbox-key-info__subtitle") {{ $t('Certificates') }}
      v-expansion-panels(
        v-model="panel"
        accordion
      )
        v-expansion-panel
          v-expansion-panel-header
            p(class="eds-iit-checkbox-key-info__text mb-0") {{ $t('EDS (DSTU 4145)') }}
          v-expansion-panel-content
            p(class="eds-iit-checkbox-key-info__subtitle") {{ $t('The certificate issued') }}
            p(class="eds-iit-checkbox-key-info__text") {{ issuer }}
            p(class="eds-iit-checkbox-key-info__subtitle") {{ $t('Serial number') }}
            p(class="eds-iit-checkbox-key-info__text") {{ serialNumber }}
            p(class="eds-iit-checkbox-key-info__subtitle") {{ $t('The certificate is valid') }}
            p(
              v-if="dateStart && dateEnd"
              class="eds-iit-checkbox-key-info__text mb-0"
            ) {{ $t('from') }} {{ dateStart }} {{ $t('to') }} {{ dateEnd }}
        v-expansion-panel
          v-expansion-panel-header
            p(class="eds-iit-checkbox-key-info__text mb-0") {{ $t('Key distribution protocols (DSTU 4145)') }}
          v-expansion-panel-content
            p(class="eds-iit-checkbox-key-info__subtitle") {{ $t('The certificate issued') }}
            p(class="eds-iit-checkbox-key-info__text") {{ issuer }}
            p(class="eds-iit-checkbox-key-info__subtitle") {{ $t('Serial number') }}
            p(class="eds-iit-checkbox-key-info__text") {{ serialNumber }}
            p(class="eds-iit-checkbox-key-info__subtitle") {{ $t('The certificate is valid') }}
            p(
              v-if="dateStart && dateEnd"
              class="eds-iit-checkbox-key-info__text mb-0"
            ) {{ $t('from') }} {{ dateStart }} {{ $t('to') }} {{ dateEnd }}
    div(class="eds-iit-checkbox-key-info__actions")
      v-btn(
        @click="$emit('confirm')"
        class="main-button"
        :loading="loading"
      ) {{ $t('Confirm') }}
</template>

<script>
export default {
  name: 'EdsIitCheckboxKeyInfo',
  props: {
    keyData: {
      type: Array,
      default: () => []
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    panel: null
  }),
  computed: {
    certificate () {
      return this._.get(this.keyData, '[0].infoEx')
    },
    title () {
      const name = this._.get(this.certificate, 'subjFullName') || this._.get(this.certificate, 'subjCN')
      return this._.toLower(name)
    },
    organization () {
      return this._.capitalize(this._.get(this.certificate, 'subjOrg')) || '–'
    },
    code () {
      return this._.get(this.certificate, 'subjDRFOCode') || this._.get(this.certificate, 'subjEDRPOUCode') || '–'
    },
    issuer () {
      return this._.get(this.certificate, 'issuerCN')
    },
    serialNumber () {
      return this._.get(this.certificate, 'serial') || '–'
    },
    dateStart () {
      const date = this._.get(this.certificate, 'certBeginTime')
      if (date) {
        return this.$moment(date).format('DD.MM.YYYY HH:mm:ss')
      }
      return null
    },
    dateEnd () {
      const date = this._.get(this.certificate, 'certEndTime')
      if (date) {
        return this.$moment(date).format('DD.MM.YYYY HH:mm:ss')
      }
      return null
    }
  }
}
</script>

<style scoped lang="scss">
.eds-iit-checkbox-key-info {
  padding: 20px;
  border-radius: 12px;
  background: rgba(0, 0, 0, 0.03);

  @media (min-width: map-get($breakpoints, 'sm')) {
    padding: 24px;
  }

  &__title {
    font-size: 16px;
    font-weight: 600;
    color: #000;
    text-transform: capitalize;
    margin-bottom: 16px;

    @media (min-width: map-get($breakpoints, 'sm')) {
      font-size: 20px;
      margin-bottom: 24px;
    }
  }

  &__subtitle {
    color: #7C7C7C;
    font-weight: 300;
    font-size: 12px;
    line-height: 22px;
    margin: 0;
  }

  &__text {
    font-weight: 600;
    color: #000;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 16px;
  }

  &__actions {
    margin-top: 24px;
    display: flex;
    justify-content: flex-end;

    @media (min-width: map-get($breakpoints, 'xs')) {
      margin-top: 32px;
    }

    .main-button {
      width: 100%;

      @media (min-width: map-get($breakpoints, 'xs')) {
        max-width: 180px;
      }
    }
  }

  &::v-deep {
    .v-expansion-panel {
      background-color: transparent !important;

      &::before {
        display: none;
      }

      &::after {
        display: none;
      }
    }

    .v-expansion-panel-header {
      padding: 4px 20px 8px;
      min-height: auto;
      gap: 8px;

      p {
        flex: none;
        width: 100%;

        @media (min-width: map-get($breakpoints, 'sm')) {
          width: auto;
        }
      }

      &__icon {
        margin-left: 0;

        i {
          color: #000 !important;
        }
      }
    }

    .v-expansion-panel-content {
      &__wrap {
        padding-left: 20px;
        padding-right: 20px;
      }
    }
  }
}
</style>
