<template lang="pug">
  div(
    v-if="value"
    class="cols-map"
  )
    v-tooltip(
      v-if="tooltip"
      right
    )
      template(#activator="{ on, attrs }")
        span(
          v-if="!chips"
          v-on="on"
        ) {{ output }}
        v-chip(
          v-bind="color"
          v-on="on"
          small
          v-else
        ) {{ output }}
      span() {{ tooltip }}
    div(v-else)
      span(v-if="!chips") {{ output }}
      v-chip(
        v-bind="color"
        small
        v-else
      ) {{ output }}
      e-link(
        v-if="hasErrorFile && !taxErrorMessage"
        @click="loadError"
        class="d-block mt-1"
        :loading="errorLoading"
        underline
      ) {{ $t('View') }}

    e-hint-tooltip(
      v-if="showError"
      icon="exclamation"
      classes="ml-1 mt-1 cursor-pointer"
      :styles="{ verticalAlign: '-4px' }"
      :onClick="openErrorModal"
      right
    )
      span {{ $t('Click to view the error') }}

    e-hint-tooltip(
      v-if="warningIcon()"
      :icon="warningIcon()"
      classes="ml-1 mt-1"
      :styles="{ verticalAlign: '-4px' }"
      right
    )
      span {{ $t(tooltipText, { count: countDaysToFinishKey }) }}
</template>

<script>
import ELink from '~/components/elements/links/e-link'
import ESvgIcon from '~/components/elements/icons/e-svg-icon'
import EHintTooltip from '~/components/elements/tooltips/e-hint-tooltip'

import contentDialog from '~/mixins/dialogs/contentDialog'

export default {
  components: {
    EHintTooltip,
    ESvgIcon,
    ELink
  },
  mixins: [
    contentDialog
  ],
  props: {
    map: {
      type: [Array, Object],
      required: true
    },
    value: {
      type: [String, Number],
      default: null
    },
    template: {
      type: String,
      default: '{value} - {text}'
    },
    chips: {
      type: Boolean,
      default: false
    },
    valueField: {
      type: String,
      default: null
    },
    randomColor: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    appearance: {
      checkbox: {
        color: '#161b25',
        textColor: '#fff'
      },
      hasRemovalOrderError: {
        color: '#161b25',
        textColor: '#fff'
      },
      hasModifyOrderError: {
        color: '#161b25',
        textColor: '#fff'
      },
      deactivated: {
        color: 'rgba(22, 27, 37, 0.1)',
        textColor: '#161b25'
      },
      deactivatedByUser: {
        color: 'rgba(22, 27, 37, 0.1)',
        textColor: '#161b25'
      },
      alert: {
        color: 'rgba(255, 170, 1, 0.2)',
        textColor: '#c37f00'
      },
      onRemovalInSfs: {
        color: 'rgba(255, 170, 1, 0.2)',
        textColor: '#c37f00'
      },
      onModifyInSfs: {
        color: 'rgba(255, 170, 1, 0.2)',
        textColor: '#c37f00'
      },
      tax: {
        color: 'rgba(22, 27, 37, 0.1)',
        textColor: '#161b25'
      },
      start: {
        color: 'rgba(22, 27, 37, 0.1)',
        textColor: '#161b25'
      },
      order: {
        color: 'rgba(255, 170, 1, 0.2)',
        textColor: '#c37f00'
      },
      error: {
        color: 'rgba(255, 0, 43, 0.1)',
        textColor: '#ff002b'
      }
    },
    countDaysForWarningMessage: 5,
    countDaysToFinishKey: null,
    tooltipText: '',
    errorLoading: false
  }),
  computed: {
    model () {
      return this._.get(this.map, 'model', null)
    },
    taxErrorMessage () {
      return this._.get(this.item, 'taxErrorMessage', null) || this._.get(this.item, 'error.taxErrorMessage', null)
    },
    hasErrorFile () {
      return this._.get(this.item, 'hasErrorFile') || this._.get(this.item, 'error.hasErrorFile')
    },
    showError () {
      return this.taxErrorMessage || this._.get(this.item, 'error', null)
    },
    item () {
      if (this.valueField && Array.isArray(this.map)) {
        return this.map.find(item => item[this.valueField] === this.value)
      } else {
        return this.map[this.value]
      }
    },
    tooltip () {
      return this.$t(this._.get(this, 'item.tooltip'))
    },
    color () {
      return this._.get(this.item, 'appearance', '') || this.appearance[this._.get(this.item, 'type', '')]
    },
    output () {
      // TODO apply full template support
      return this.template.replace(/{value}/gi, this.value).replace(/{text}/gi, this.$t(this._.get(this.item, 'text', '')))
    },
    certificateEnd () {
      return this._.get(this.map, 'certificateEnd', null)
    }
  },
  methods: {
    warningIcon () {
      const date = new Date()
      if (this.certificateEnd === null) {
        return false
      } else if (date.getTime() > new Date(this.certificateEnd)) {
        this.tooltipText = 'Having ended the term of the EDS key of the given cashier'
        return 'exclamation'
      } else if (date.setDate(date.getDate() + this.countDaysForWarningMessage) > new Date(this.certificateEnd)) {
        const dateEnd = new Date(this.certificateEnd)
        let delta = Math.floor((dateEnd - new Date()) / 1000)
        const days = Math.floor(delta / 86400)
        delta -= days * 86400
        const hours = Math.floor(delta / 3600) % 24
        delta -= hours * 3600
        const minutes = Math.floor(delta / 60) % 60
        let str = `${days}д`
        if (hours) {
          str += ` ${hours}г`
        }
        if (minutes) {
          str += ` ${minutes}хв`
        }
        this.countDaysToFinishKey = str
        this.tooltipText = 'The term for the EDS key of the given cashier will end in days'
        return 'warning-2'
      }
    },
    async loadError () {
      try {
        this.errorLoading = true
        const requestId = this._.get(this.item, 'error.id') || this._.get(this.map, 'id')
        const res = await this.model.api().get(this.model.$routes[this.model.entity].error(requestId), {
          responseType: 'blob'
        })
        const blob = new Blob([this._.get(res, 'response.data', '')], { type: 'application/pdf' })
        window.open(URL.createObjectURL(blob), '_blank')
      } catch (e) {
        this.$handlers.error(e, this)
      } finally {
        this.errorLoading = false
      }
    },
    async openErrorModal () {
      await this.contentDialog.open({
        component: 'block-order-error',
        width: '600px',
        componentProps: {
          hasErrorFile: this.hasErrorFile,
          message: this.taxErrorMessage,
          orderId: this._.get(this.item, 'error.id') || this._.get(this.map, 'id'),
          model: this.model
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.cols-map {
  display: flex;
  padding: 10px 0;

  &__hint {
    color: $color-warning;
    font-weight: 700;
  }

  &::v-deep {
    .v-chip {
      border-radius: 8px;
      padding: 0 7px;
      font-size: .69rem;
      height: 22px;
    }

    .e-link {
      font-size: .59rem !important;
    }
  }
}
</style>
