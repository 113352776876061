<template lang="pug">
  v-form(class="change-name")
    h2(class="mb-6") {{ $t('Change full name') }}
    ValidationObserver(ref="changeNameForm")
      t-orm-fields(:fullName.sync="fullName" :items="fields")
    div(class="change-name__actions")
      v-btn(
        class="flex-grow-1"
        height="48"
        @click="closeModal(false)"
      ) {{ $t('Cancel') }}
      v-btn(
          class="main-button flex-grow-1"
          height="48"
          :loading="loading"
          @click="submit"
      ) {{ $t('Change') }}
</template>

<script>
import TOrmFields from '~/components/templates/orm/t-orm-fields'
import User from '~/models/administration/User'
import responsive from '~/mixins/pages/responsive'

export default {
  name: 'FormChangeName',
  components: {
    TOrmFields
  },
  mixins: [responsive],
  props: {
    closeModal: {
      type: Function,
      default: () => ({})
    },
    currentName: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    fullName: '',
    loading: false
  }),
  computed: {
    fields () {
      return [
        {
          model: 'fullName',
          component: 'v-text-field',
          provider: {
            name: 'Full name',
            vid: 'firstName',
            rules: 'required|min:3',
            mode: 'lazy'
          },
          attrs: {
            label: 'Enter full name',
            filled: true,
            required: true
          }
        }
      ]
    }
  },
  created () {
    this.fullName = this.currentName
  },
  methods: {
    async submit () {
      const valid = await this.$refs.changeNameForm.validate()

      if (valid) {
        try {
          this.loading = true
          await User.api().updateUserProfile({ name: this.fullName })
          this.closeModal(true)
          this.$notification.success(this.$t('Name has been changed successfully'))
        } catch (e) {
          this.$handlers.error(e, this)
        } finally {
          this.loading = false
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
.change-name {
  color: $dark;

  &__actions {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    gap: 16px;
  }
}
</style>
