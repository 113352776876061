<template lang="pug">
  div(class="documents-sign-results")
    div(class="documents-sign-results__icon")
      v-img(:src="require('~/assets/images/party-popper.png')" max-width="80")
    h2(class="title-32px mb-2") {{ $t('Documents signed') }}
    div(class="text-14px") {{ text }}
    div(v-if="failedDocuments.length" class="documents-sign-results__failed-documents")
      div(
        v-for="(document, idx) in failedDocuments"
        :key="idx"
        class="documents-sign-results__failed-item"
      )
        div(class="d-flex align-center justify-space-between")
          div(class="text-left")
            div(class="documents-sign-results__failed-item-title") {{ document.title }}
            div(
              class="documents-sign-results__failed-item-reason documents-sign-results__failed-item-reason--error"
            ) {{ document.reason }}
          div
            div(class="documents-sign-results__failed-item-icon")
              e-svg-icon close-circle-red
    div(class="documents-sign-results__actions")
      v-btn(class="main-button w-100" @click="handleClick") {{ $t('Ok') }}
</template>

<script>
import ESvgIcon from '~/components/elements/icons/e-svg-icon'

export default {
  name: 'BlockDocumentsSignResults',
  components: {
    ESvgIcon
  },
  props: {
    closeModal: {
      type: Function,
      default: () => {}
    },
    failedDocuments: {
      type: Array,
      default: () => []
    },
    isInsideModal: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    text () {
      return this.failedDocuments.length ? this.$t('The process completed with some errors. Please check the indicated errors and try to sign these documents again') : this.$t('All selected documents were successfully signed')
    }
  },
  methods: {
    handleClick () {
      if (this.isInsideModal) {
        this.closeModal()
      } else {
        this.$emit('back')
      }
    }
  }
}
</script>

<style scoped lang="scss">
.documents-sign-results {
  &__icon {
    display: flex;
    justify-content: center;
    padding-bottom: 16px;
  }
  &__failed-documents {
    margin-top: 16px;

    border-radius: 12px;
    background: rgba(18, 18, 18, 0.03);
  }
  &__failed-item {
    padding: 12px;

    &-title {
      color: $text-dark;
      font-size: 14px;
      font-weight: 300;
      line-height: 20px;
    }

    &-reason {
      font-size: 12px;
      font-weight: 400;
      line-height: 20px;
      padding-right: 8px;

      &--error {
        color: $danger;
      }
    }
  }
  &__actions {
    padding-top: 32px;
  }
}
</style>
