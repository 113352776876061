import { VBtn } from 'vuetify/lib/components/VBtn';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"form-tag"},[_c('h2',{staticClass:"form-tag__title"},[_vm._v(_vm._s(_vm.title))]),(!_vm.isEdit)?_c('div',{staticClass:"form-tag__text"},[_vm._v(_vm._s(_vm.$t('Help organize documents by providing a convenient way to quickly find, sort and use them')))]):_vm._e(),_c(VForm,{on:{"submit":function($event){$event.preventDefault();$event.stopPropagation();return _vm.onSubmit($event)}}},[_c('ValidationObserver',{ref:"form",attrs:{"slim":""}},[_c('ValidationProvider',{attrs:{"rules":"required","vid":"name","name":_vm.wrapQuotesProviderName(_vm.$t('Tag name')),"mode":"lazy"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c(VTextField,{attrs:{"filled":"","error-messages":errors,"label":_vm.$t('Enter name of new tag')},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})]}}])}),_c('div',{staticClass:"form-tag__label mb-4"},[_vm._v(_vm._s(_vm.$t('Choose tag color')))]),_c('ValidationProvider',{attrs:{"rules":"required","vid":"color","name":_vm.wrapQuotesProviderName(_vm.$t('Tag color')),"mode":"lazy"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c(VRadioGroup,{staticClass:"form-tag__radio-group",attrs:{"row":"","error-messages":errors},model:{value:(_vm.form.color),callback:function ($$v) {_vm.$set(_vm.form, "color", $$v)},expression:"form.color"}},_vm._l((_vm.colors),function(colorItem){return _c(VBtn,{key:colorItem.color,staticClass:"form-tag__color-picker",attrs:{"icon":""},on:{"click":function($event){return _vm.selectColor(colorItem.color)}}},[_c('div',{staticClass:"form-tag__color",style:({ backgroundColor: colorItem.color })},[(colorItem.color === _vm.form.color)?_c('e-svg-icon',{attrs:{"size":"xs"}},[_vm._v("check-white")]):_vm._e()],1)])}),1)]}}])}),_c('div',{staticClass:"form-tag__actions"},[_c(VBtn,{attrs:{"height":"48"},on:{"click":_vm.closeModal}},[_vm._v(_vm._s(_vm.$t('Cancel')))]),_c(VBtn,{staticClass:"main-button",attrs:{"type":"submit","loading":_vm.loading}},[_vm._v(_vm._s(_vm.buttonText))])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }