<template lang="pug">
  div(class="organizations-success")
    div(class="organizations-success__image")
      v-img(:src="require('~/assets/images/check-green.png')" :max-width="xsDevice ? 56 : 80")
    div(class="organizations-success__content")
      h3(class="mb-2" :class="[xsDevice ? 'title-20px': 'title-32px']") {{ $t(title) }}
      p(class="text-14px") {{ $t(text) }}
    div(class="organizations-success__actions")
      v-btn(@click="closeModal" class="main-button w-100" height="48") {{ $t('Ok') }}
</template>

<script>
import responsive from '~/mixins/pages/responsive'

export default {
  name: 'BlockOrganizationsSuccess',
  mixins: [responsive],
  props: {
    closeModal: {
      type: Function,
      default: () => {}
    },
    title: {
      type: String,
      default: null
    },
    text: {
      type: String,
      default: null
    }
  }
}
</script>

<style scoped lang="scss">
.organizations-success {
  &__image {
    display: flex;
    justify-content: center;
    margin-bottom: 24px;
  }
  &__content {
    text-align: left;
  }
  &__btn {
    flex-grow: 1;
  }
  &__actions {
    padding-top: 32px;
  }
}
</style>
