<template lang="pug">
  div(class="profile-api-keys-delete")
    div(class="profile-api-keys-delete__image")
      v-img(:src="require('~/assets/images/warning.png')" max-width="80")
    div(class="profile-api-keys-delete__content")
      h2(class="title-32px mb-2") {{ $t('Revoke key') }}
      div(class="text-14px mb-8") {{ $t('This API key will be disabled immediately. APl requests made using this key will be rejected. Once revoked, you will no longer be able to view or change this API key') }}
    div(class="profile-api-keys-delete__actions")
      v-btn(
        height="48"
        @click="closeModal"
      ) {{ $t('Cancel') }}
      v-btn(
        class="secondary-button error-btn"
        @click="submit"
        :loading="loading"
      ) {{ $t('Delete key') }}
</template>

<script>
import AccessToken from '~/modules/profile/models/AccessToken'

export default {
  name: 'BlockProfileApiKeysDelete',
  props: {
    closeModal: {
      type: Function,
      default: () => {}
    },
    item: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      loading: false
    }
  },
  methods: {
    async submit () {
      try {
        this.loading = true
        await AccessToken.api().del(this.item)
        this.closeModal()
      } catch (e) {
        this.$handlers.error(e, this)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
.profile-api-keys-delete {
  &__image {
    display: flex;
    justify-content: center;
    margin-bottom: 24px;
  }
  &__content {
    text-align: left;
  }
  &__actions {
    display: grid;
    grid-template-rows: 1fr 1fr;
    gap: 16px;

    @media (min-width: map-get($breakpoints, 'md')) {
      grid-template-columns: 1fr 1fr;
      grid-template-rows: none;
    }
  }
}
</style>
