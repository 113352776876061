import moment from 'moment'
import { formats } from '~/const/global'

export class DateTime {
  static toTime (date) {
    return moment(date).format(formats.time)
  }

  static format (date, format = formats.date) {
    if (!date) { return null }
    return moment(date).format(format)
  }

  static dateObjectToDateType (dateObject) {
    const date = new Date(dateObject.wYear + '/' + dateObject.wMonth + '/' + dateObject.wDay + ' ' + dateObject.wHour + ':' + dateObject.wMinute + ':' + dateObject.wSecond)
    return moment(date).format(formats.dateTimeSec)
  }
}
