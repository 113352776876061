const html2pdf = {
  methods: {
    savePdf (elemSelector) {
      const element = document.querySelector(elemSelector)
      window.html2pdf().from(element).save()
    },
    canvasToBase64Pdf (element, width, height) {
      const options = {
        image: { type: 'jpeg', quality: 1 },
        html2canvas: { scale: 1 },
        jsPDF: { orientation: width > height ? 'landscape' : 'portrait' }
      }
      return window.html2pdf()
        .from(element)
        .set(options)
        .outputPdf('datauristring')
        .then(result => result)
    }
  }
}

export default html2pdf
