import { VBtn } from 'vuetify/lib/components/VBtn';
import { VForm } from 'vuetify/lib/components/VForm';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"form-documents-mass-share"},[_c('h2',{staticClass:"title-32px mb-8"},[_vm._v(_vm._s(_vm.$t('Share these documents')))]),_c(VForm,{on:{"submit":function($event){$event.preventDefault();$event.stopPropagation();return _vm.submit($event)}}},[_c('ValidationObserver',{ref:"form",attrs:{"slim":""}},[_vm._l((_vm.participants),function(participant,idx){return _c('div',{key:idx,staticClass:"form-documents-mass-share__participant",class:{ 'with-delete': _vm.participants.length > 1 }},[_c('ValidationProvider',{attrs:{"rules":_vm.setIdentifierRules(participant, idx),"vid":("identifier" + idx),"name":_vm.wrapQuotesProviderName(_vm.$t('Email or Edprou')),"mode":"lazy"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c(VTextField,{attrs:{"filled":"","hide-details":"auto","error-messages":errors,"label":_vm.$t('Enter user email or EDRPOU')},model:{value:(participant.identifier),callback:function ($$v) {_vm.$set(participant, "identifier", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"participant.identifier"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required","vid":("action" + idx),"name":_vm.wrapQuotesProviderName(_vm.$t('What have to do')),"mode":"lazy"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c(VSelect,{attrs:{"items":_vm.participantActions,"label":_vm.$t('What have to do'),"filled":"","hide-details":"auto","error-messages":errors,"menuProps":{ bottom: true, offsetY: true }},model:{value:(participant.action),callback:function ($$v) {_vm.$set(participant, "action", $$v)},expression:"participant.action"}})]}}],null,true)}),(_vm.participants.length > 1)?_c(VBtn,{staticClass:"secondary-button error-btn",attrs:{"width":"48","icon":""},on:{"click":function($event){return _vm.deleteParticipant(idx)}}},[_c('e-svg-icon',[_vm._v("danger-trash")])],1):_vm._e()],1)}),_c(VBtn,{staticClass:"form-documents-mass-share__add-btn mt-4",attrs:{"text":""},on:{"click":_vm.addParticipant}},[_vm._v(_vm._s(_vm.$t('Add participant')))])],2),_c('div',{staticClass:"form-documents-mass-share__actions pt-6"},[_c(VBtn,{attrs:{"height":"48"},on:{"click":function($event){return _vm.closeModal(false)}}},[_vm._v(_vm._s(_vm.$t('Cancel')))]),_c(VBtn,{staticClass:"main-button",attrs:{"type":"submit","loading":_vm.loading}},[_vm._v(_vm._s(_vm.$t('Share')))])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }