import CurrentOrganization from '~/modules/auth/Models/CurrentOrganization'

const currentOrganizationMixin = {
  computed: {
    currentOrganization () {
      return CurrentOrganization.query().withAll().first()
    }
  }
}

export default currentOrganizationMixin
