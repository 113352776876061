import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.permittedItems.length)?_c(VRow,{staticClass:" t-orm-menu-actions"},[_c(VCol,{staticClass:"py-0 d-flex justify-center",attrs:{"cols":"12"}},[_c(VMenu,{attrs:{"left":"","bottom":"","content-class":"action-icons","nudge-left":"30"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VBtn,_vm._g({attrs:{"icon":""}},on),[_c(VIcon,[_vm._v("mdi-dots-horizontal")])],1)]}}],null,false,2955327160)},[_c(VList,{staticClass:"py-0",attrs:{"dense":""}},[_vm._l((_vm.permittedItems),function(item,i){return [(_vm.isVisible(item))?_c(VListItem,_vm._b({key:i,on:{"click":function($event){return _vm.itemCall(item)}}},'v-list-item',_vm.attrs(item),false),[(item.icon)?_c(VListItemIcon,[_c(item.icon.type,_vm._b({tag:"component",class:_vm.iconClasses(item),attrs:{"name":_vm.iconName(item),"text":_vm.iconName(item)}},'component',item.icon.attrs ? item.icon.attrs : {},false))],1):_vm._e(),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(_vm._.upperFirst(_vm.$t(_vm.text(item)))))])],1)],1):_vm._e(),(item.length > 1)?_c('hr',{staticClass:"align-self mx-auto"}):_vm._e()]})],2)],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }