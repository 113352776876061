import { get } from 'lodash/object'
import Shortcode from '~/modules/shortcodes/models/Shortcode'

export default async function ({ redirect, route }, force = false, silent = true) {
  try {
    const { token, documentId } = get(await Shortcode.api().filter({ save: true }).read(route.params.id), 'response.data')
    return redirect({
      name: 'documents-id',
      params: {
        id: documentId
      },
      query: {
        token
      }
    })
  } catch (e) {
    const errorCode = get(e, 'response.data.code')
    if (errorCode === 403) {
      throw e
    }
    if (!silent) { throw e }
  }
}
