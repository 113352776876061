import Snackbar from '~/modules/snackbar/models/Snackbar'

const informationSnackbar = {
  computed: {
    informationSnackbar () {
      return Snackbar.query().where('type', 'information').first()
    }
  }
}
export default informationSnackbar
