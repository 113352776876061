const edsIitCheckboxSteps = {
  computed: {
    steps () {
      return {
        options: 'options',
        fileSign: 'fileSign',
        smartIdRead: 'smartIdRead',
        smartIdSign: 'smartIdSign',
        keyInfo: 'keyInfo',
        diiaRead: 'diiaRead',
        diiaSign: 'diiaSign'
      }
    }
  }
}

export default edsIitCheckboxSteps
