import Vue from 'vue'
// import * as _ from 'lodash'

const defOptions = {
  component: 'm-dialog-base'
}

export const state = {
  dialogs: {
    mDialogInfo: {
      component: 'm-dialog-info',
      options: {
        text: '',
        title: '',
        button: {
          show: true,
          text: '',
          action: null
        },
        visible: false
      }
    }
  }
}

export const getters = {
  list: () => state.dialogs
}

export const actions = {
  open ({ commit }, dialog) {
    commit('CREATE_DIALOG', Object.assign({}, defOptions, dialog))

    commit('OPEN_DIALOG', dialog)

    return new Promise((resolve, reject) => {
      commit('SAVE_PROMISES', {
        dialog,
        resolve,
        reject
      })
    })
  },

  close ({ commit }, dialog) {
    commit('CLOSE_DIALOG', dialog)
    commit('DESTROY_DIALOG', dialog)
  },

  destroy ({ commit }, dialog) {
    commit('DESTROY_DIALOG', dialog)
  },

  openWithOptions ({ commit }, dialog) {
    commit('SET_DIALOG_OPTIONS', dialog)
    commit('OPEN_DIALOG', dialog)
  },

  closeWithOptions ({ commit }, dialog) {
    commit('SET_DIALOG_OPTIONS', dialog)
    commit('CLOSE_DIALOG', dialog)
  }
}

export const mutations = {
  CREATE_DIALOG (state, dialog) {
    Vue.set(state.dialogs, dialog.type, dialog)
  },

  OPEN_DIALOG (state, dialog) {
    Vue.set(state.dialogs[dialog.type].options, 'visible', true)
  },

  CLOSE_DIALOG (state, dialog) {
    Vue.set(state.dialogs[dialog.type].options, 'visible', false)
  },

  SAVE_PROMISES (state, payload) {
    state.dialogs[payload.dialog.type].resolve = payload.resolve
    state.dialogs[payload.dialog.type].reject = payload.reject
  },

  SET_DIALOG_OPTIONS (state, dialog) {
    state.dialogs[dialog.type] = {
      ...state.dialogs[dialog.type],
      ...dialog,
      options: {
        ...state.dialogs[dialog.type]?.options,
        ...dialog.options
      },
      actions: {
        ...state.dialogs[dialog.type]?.actions,
        ...dialog.actions
      }
    }
  },

  DESTROY_DIALOG (state, dialog) {
    Vue.delete(state.dialogs, dialog.type)
  }
}
