<template lang="pug">
  v-menu(:value="value" @input="$emit('input')" offset-x)
    template(v-slot:activator="{ on, attrs }")
      v-btn(
        icon
        v-bind="attrs"
        @click.stop.capture="$emit('onSettings', tag.id)"
      )
        e-svg-icon three-dots-outlined
    v-list(class="menu-list")
      template(v-for="(action, idx) in tagActions")
        template(v-if="action.innerMenu")
          v-menu(right offset-x :close-on-content-click="false")
            template(v-slot:activator="{ on }")
              v-list-item(v-if="isVisible(action, tag)" :key="idx" v-on="on" class="menu-list__item")
                v-list-item-title(class="text-14px dark") {{ $t(action.text) }}
                v-list-item-icon(v-if="action.icon" class="ma-0 h-100 d-flex align-center")
                  e-svg-icon(:name="action.icon")
            v-list(class="menu-list")
              v-subheader {{ $t('In documents list') }}
              v-list-item(v-for="(option, idx) in action.innerMenu" :key="idx" class="menu-list__item" @click="handleOptionClick(option)")
                v-list-item-title(class="text-14px dark") {{ $t(option.text) }}
                v-list-item-icon(v-if="visibilityTags[tag.id] === option.value" class="ma-0 h-100 d-flex align-center")
                  e-svg-icon check-primary
        template(v-else)
          v-list-item(v-if="isVisible(action)" :key="idx" @click="action.call(tag)" class="menu-list__item")
            v-list-item-title(class="text-14px" :class="[isRemoveAction(action) ? 'color-error' : 'dark']") {{ $t(action.text) }}
            v-list-item-icon(v-if="action.icon" class="ma-0 h-100 d-flex align-center")
              e-svg-icon(:name="action.icon")
</template>

<script>
import tags from '~/modules/tags/mixins/tags'
import ESvgIcon from '~/components/elements/icons/e-svg-icon'
import checkVisibility from '~/mixins/checkVisibility'

export default {
  name: 'ETagsMenuActions',
  components: {
    ESvgIcon
  },
  mixins: [tags, checkVisibility],
  props: {
    value: {
      type: Boolean,
      default: false
    },
    visibilityTags: {
      type: Object,
      default: null
    },
    tag: {
      type: Object,
      default: null
    }
  },
  methods: {
    isRemoveAction (action) {
      return ['delete', 'remove'].includes(action.name)
    },
    handleOptionClick (option) {
      this.$emit('updateVisibility', this.tag.id, option.value)
      option.call(this.tag, option.value)
    }
  }
}
</script>

<style scoped lang="scss"></style>
