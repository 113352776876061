import OrmModel from '~/models/abstracts/base/OrmModel'

export default class TemplateField extends OrmModel {
  static entity = 'templatefield'

  static STATUSES = {
    new: 'new',
    created: 'created',
    to_delete: 'to_delete'
  }

  static TYPES = {
    text: 'text',
    date: 'date',
    checkbox: 'checkbox',
    select: 'select'
  }

  static fields () {
    return {
      id: this.attr(null),
      documentFieldId: this.attr(null),
      documentFieldValue: this.attr(null),
      documentFieldPreValue: this.attr(null),
      key: this.attr(null),
      type: this.attr(null),
      hint: this.attr(null),
      textSize: this.attr(null),
      positionX: this.attr(null),
      positionY: this.attr(null),
      createdAt: this.attr(null),
      width: this.attr(null),
      height: this.attr(null),
      status: this.attr(null),
      changed: this.boolean(false),
      options: this.attr(null)
    }
  }
}
