import Dialog from '~/models/system/Dialog'
import EdsIitCheckbox from '~/modules/eds-iit-checkbox/models/EdsIitCheckbox'
import edsIitCheckboxHelper from '~/modules/eds-iit-checkbox/mixins/edsIitCheckboxHelper'

const edsIitCheckboxDialog = {
  mixins: [edsIitCheckboxHelper],
  methods: {
    getEdsIitCheckboxDialog () {
      return Dialog.query().where('type', 'edsIitCheckboxDialog').first()
    },
    getEdsIitCheckboxModel (type) {
      return EdsIitCheckbox.query().where('type', type).first()
    },
    getEdsIitCheckboxInterface (type) {
      const modelInstance = this.getEdsIitCheckboxModel(type)
      return modelInstance ? modelInstance.getInterface() : null
    },
    async closeEdsIitCheckboxDialog (type, hide = false) {
      const modelInstance = this.getEdsIitCheckboxModel(type)
      if (modelInstance && !hide) {
        await modelInstance.delete()
      }
      const dialog = this.getEdsIitCheckboxDialog()
      if (dialog) {
        await dialog.close()
      }
    },
    async handleOnConfirmEdsIitCheckboxDialog (params = {}) {
      const {
        onConfirm,
        type,
        signInterface,
        dialog,
        keyCheck
      } = params
      if (typeof onConfirm === 'function') {
        const data = await this.generateSignInterfacePayloadAndErrorsCheck({
          type,
          keyCheck,
          signInterface,
          errorFallback: async () => {
            if (dialog) {
              await dialog.close()
            }
          }
        })
        return await onConfirm(data)
      }
    },
    async useEdsIitCheckboxDialog ({
      type = 'edsIitCheckboxForOrganization',
      method = 'use',
      keyCheck = true,
      onlyFileSign = false,
      saveDataInterfaceAfterClose = false,
      pluginTitles = {},
      dialogParams = {},
      onConfirm
    } = {}) {
      const dialog = this.getEdsIitCheckboxDialog()
      const edsIitCheckboxDialogParams = {
        open: method === 'open',
        pluginType: type,
        pluginTitles,
        onlyFileSign,
        saveDataInterfaceAfterClose,
        ...(dialogParams || {})
      }
      if (this._.isFunction(onConfirm)) {
        edsIitCheckboxDialogParams.onConfirm = async (signInterface) => {
          return await this.handleOnConfirmEdsIitCheckboxDialog({
            onConfirm,
            type,
            signInterface,
            dialog,
            keyCheck
          })
        }
      }
      return await dialog.use(edsIitCheckboxDialogParams)
    }
  }
}
export default edsIitCheckboxDialog
