<template lang="pug">
  v-menu(
    v-if="$User"
    v-model="profileMenu"
    offset-y
    content-class="profile-menu"
    :close-on-content-click="false"
  )
    template(v-slot:activator="{ on }")
      v-btn(
        v-on="on"
        class="profile-button"
        :class="{ 'circled': !currentOrganization || xsDevice }"
        :icon="!currentOrganization || xsDevice"
        height="48"
        @click="profileMenu = !profileMenu"
      )
        template(v-if="currentOrganization && !xsDevice")
          v-img(:src="require('~/assets/images/bagcase.png')" max-width="16")
          span(v-if="currentOrganizationName" class="profile-button__text px-2") {{ currentOrganizationName }}
          span(class="profile-button__arrow" :class="{ 'active': profileMenu }")
            e-svg-icon(size="xsm") arrow-down
        template(v-else)
          v-img(
            v-if="!profileMenu"
            :src="require('~/assets/images/person.png')"
            max-width="16"
          )
          v-icon(v-else) mdi-close
    block-profile-menu-content
</template>

<script>
import ESvgIcon from '~/components/elements/icons/e-svg-icon'
import CurrentOrganizationMixin from '~/modules/organizations/mixins/currentOrganizationMixin'
import responsive from '~/mixins/pages/responsive'
import BlockProfileMenuContent from '~/modules/profile/views/components/block-profile-menu-content'

export default {
  name: 'BlockProfileMenu',
  components: {
    BlockProfileMenuContent,
    ESvgIcon
  },
  mixins: [CurrentOrganizationMixin, responsive],
  data () {
    return {
      profileMenu: false
    }
  },
  computed: {
    currentOrganizationName () {
      return this._.get(this.currentOrganization, 'name', null)
    }
  }
}
</script>

<style scoped lang="scss">
.profile-button {
  display: flex;
  align-items: center;
  justify-content: center;

  background: rgba(0, 0, 0, 0.03) !important;

  color: $text-dark !important;
  font-size: 12px !important;
  font-weight: 700;
  line-height: 20px !important;

  &__text {
    max-width: 180px;
    overflow: hidden;
    font-size: 12px;
    line-height: 16px;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #000;

    @media (min-width: map-get($breakpoints, 'xl')) {
      max-width: 250px;
    }
  }

  &.circled {
    width: 48px !important;
  }

  &__arrow {
    transition: transform linear .2s;

    &.active {
      transform: rotateZ(180deg);
    }
  }
}
.profile-menu {
  box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.08);
  background: #FFFFFF;
  width: 290px;

  @media (min-width: map-get($breakpoints, 'xl')) {
    width: 350px;
  }
}
</style>
