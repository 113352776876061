<template lang="pug">
  div
    template(v-for="(dialog, index) in dialogs")
      component(
        v-if="dialog.component"
        :is="dialog.component"
        v-bind="dialog.options"
        :key="index"
      )
    m-dialog-information()
    m-dialog-confirmation()
    m-dialog-content()
    m-dialog-content(type="content_top")
    dialog-eds-iit-checkbox()
</template>

<script>
import { mapGetters } from 'vuex'
import MDialogBase from '~/components/modules/dialogs/m-dialog-base'
import MDialogInfo from '~/components/modules/dialogs/m-dialog-info'
import MDialogConfirmation from '~/components/modules/dialogs/common/m-dialog-confirmation'
import MDialogInformation from '~/components/modules/dialogs/common/m-dialog-information'
import MDialogContent from '~/components/modules/dialogs/common/m-dialog-content'
import DialogEdsIitCheckbox from '~/modules/eds-iit-checkbox/views/dialog-eds-iit-checkbox'

export default {
  components: {
    MDialogContent,
    MDialogInformation,
    MDialogBase,
    MDialogInfo,
    MDialogConfirmation,
    DialogEdsIitCheckbox
  },
  computed: {
    ...mapGetters({
      dialogs: 'dialogs/list'
    })
  }
}
</script>

<style scoped>

</style>
