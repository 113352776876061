<template lang="pug">
  div(class="e-divider-line-text")
    div(class="e-divider-line-text__text") {{ text }}
</template>

<script>
export default {
  name: 'EDividerLineText',
  props: {
    text: {
      type: String,
      default: 'Або'
    }
  }
}
</script>

<style scoped lang="scss">
.e-divider-line-text {
  position: relative;
  width: 100%;
  height: 1px;
  background: rgba(0, 0, 0, 0.16);

  &__text {
    color: rgba(0, 0, 0, 0.50);
    font-size: 14px;
    font-weight: 300;
    line-height: 20px;
    background: #FFFFFF;

    padding: 4px 12px;

    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 1;
    transform: translateX(-50%) translateY(-50%);
  }
}
</style>
