<template lang="pug">
  v-form(@submit.prevent.stop="editTitle")
    ValidationObserver(ref="title" slim)
      ValidationProvider(rules="min:3" v-slot="{ errors }")
        v-text-field(
          v-model="newTitle"
          filled
          :label="$t('Document name')"
          :error-messages="errors"
          @blur="editTitle"
        )
      v-btn(type="submit" :loading="editLoading" class="w-100 main-button") {{ $t('Save') }}
</template>

<script>
import { ValidationProvider } from 'vee-validate'
import responsive from '~/mixins/pages/responsive'

export default {
  name: 'FormDocumentRename',
  components: {
    ValidationProvider
  },
  mixins: [responsive],
  props: {
    model: {
      type: Function,
      default: () => {}
    },
    document: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      newTitle: null,
      editLoading: false
    }
  },
  created () {
    if (this.document) {
      this.newTitle = this.document.title
    }
  },
  methods: {
    async editTitle () {
      if (this.document.title === this.newTitle) {
        this.$emit('close')
        return
      }
      const valid = await this.$refs.title.validate()
      if (!valid) {
        return
      }
      try {
        this.editLoading = true
        await this.model.api().update(this.document, { title: this.newTitle })
        await this.model.api().read(this.document.id)
        this.$notification.success(this.$t('Document title has successfully changed'))
        // TODO: rethink approach
        if (this.xsDevice) {
          this.$emit('close')
        }
      } catch (e) {
        this.$handlers.error(e, this)
      } finally {
        this.editLoading = false
      }
    }
  }
}
</script>

<style scoped lang="scss">

</style>
