const responsive = {
  computed: {
    xsDevice () {
      return this.$vuetify.breakpoint.xs
    },
    smDevice () {
      return this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm
    },
    mdDevice () {
      return this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.md
    },
    lgDevice () {
      return this.$vuetify.breakpoint.lg
    },
    xlDevice () {
      return this.$vuetify.breakpoint.xl
    }
  }
}

export default responsive
