import { VDivider } from 'vuetify/lib/components/VDivider';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"profile-menu-content"},[_c('div',{staticClass:"profile-menu-content__icon"},[_c(VImg,{attrs:{"src":require('~/assets/images/person.png'),"max-width":"16"}})],1),_c('div',{staticClass:"profile-menu-content__title"},[_vm._v(_vm._s(_vm.$t('Welcome')))]),_c('div',{staticClass:"profile-menu-content__email"},[_vm._v(_vm._s(_vm.$User.email))]),_c('div',{staticClass:"px-3 w-100"},[_c('e-organization-change',{staticClass:"mb-4"})],1),(!_vm.xsDevice)?_c(VDivider,{staticClass:"w-100"}):_vm._e(),_c(VList,{staticClass:"w-100 py-0",attrs:{"dense":""}},[_c(VListItemGroup,[_vm._l((_vm.menuItems),function(item,idx){return [(_vm.isVisible(item))?_c(VListItem,{key:idx,staticClass:"py-2",attrs:{"dense":""},on:{"click":item.call}},[_c(VListItemTitle,{staticClass:"link__text"},[_vm._v(_vm._s(item.text))])],1):_vm._e()]})],2)],1),(_vm.showAboutUs)?[_c(VDivider,{staticClass:"w-100"}),_c('div',{staticClass:"pt-3 px-3 pb-4 w-100"},[_c('div',{staticClass:"profile-menu-content__email mb-3 text-left w-100"},[_vm._v(_vm._s(_vm.$t('About us')))]),_c('block-profile-socials')],1)]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }