import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.value)?_c('span',[(_vm.tooltip)?_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VRow,{attrs:{"no-gutters":""}},[_c(VCol,_vm._g({attrs:{"cols":"auto"}},on),[_c('span',{staticClass:"hidden-value"},[_vm._v(_vm._s(_vm.value))]),_c('span',{style:(_vm.style)},[_vm._v(_vm._s(_vm.visibleText))])]),(_vm.clipboardCopy)?_c(VCol,{staticClass:"ml-1",staticStyle:{"cursor":"pointer"},attrs:{"cols":"1"},on:{"click":_vm.copyToClipboard}},[_c('e-svg-icon',[_vm._v("copy")])],1):_vm._e()],1)]}}],null,false,1646088500)},[_c('span',[_vm._v(_vm._s(_vm.tooltip))])]):[_c('span',{attrs:{"title":_vm.value}},[_vm._v(_vm._s(_vm.visibleText))])]],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }