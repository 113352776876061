<template lang="pug">
  v-menu(
    v-model="supportMenu"
    offset-y
    left
    content-class="support-buttons__content"
    :close-on-content-click="false"
  )
    template(v-slot:activator="{ on }")
      v-btn(
        icon
        v-on="on"
        class="support-button"
        @click="supportMenu = !supportMenu"
      )
        e-svg-icon question-box
    div(class="support-buttons")
      div(class="support-buttons__text mb-6") Підтримка (09:00 - 21:00)
      div(class="d-flex align-center justify-space-between mb-4")
        div
          div(class="support-buttons__label") Telegram
          div(class="support-buttons__tag") @Dubi_Doc_UA_bot
        v-btn(icon class="support-buttons__icon" @click="openLink('https://t.me/Dubi_Doc_UA_bot')")
          v-img(:src="require('~/assets/sprite/svg/telegram.svg')" max-width="20")
      div(class="d-flex align-center justify-space-between")
        div
          div(class="support-buttons__label") Email
          div(class="support-buttons__tag") dubidoc@checkbox.ua
        e-link(class="support-buttons__icon" href="mailto:dubidoc@checkbox.ua")
          e-svg-icon sms
</template>

<script>
import ESvgIcon from '~/components/elements/icons/e-svg-icon'
import ELink from '~/components/elements/links/e-link'

export default {
  name: 'MSupportButtons',
  components: {
    ESvgIcon,
    ELink
  },
  data () {
    return {
      supportMenu: false
    }
  },
  methods: {
    openLink (url) {
      window.open(url, '_blank')
    }
  }
}
</script>

<style scoped lang="scss">
.support-button {
  width: 40px !important;
  height: 40px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;

  @media (min-width: map-get($breakpoints, 'sm')) {
    width: 48px !important;
    height: 48px !important;
  }
}
.support-buttons {
  width: 288px;
  border-radius: 16px;
  background: #FFF;
  padding: 16px;

  &__content {
    box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.08);
  }

  &__text {
    color: rgba(3, 1, 2, 0.64);
    font-variant-numeric: slashed-zero;
    font-size: 12px;
    font-weight: 300;
    line-height: 18px;
  }
  &__label {
    color: rgba(3, 1, 2, 0.64);
    font-variant-numeric: slashed-zero;
    font-size: 12px;
    font-weight: 300;
    line-height: 16px;
  }
  &__tag {
    color: #000;
    font-variant-numeric: slashed-zero;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
  }
  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 42px !important;
    height: 42px !important;

    border-radius: 12px;
    background: rgba(0, 0, 0, 0.03);
  }
}
</style>
