import { Model } from '@vuex-orm/core'
import OrmModel from '~/models/abstracts/base/OrmModel'
import ChainInheritance from '~/models/mixins/ChainInheritance'

export default class Shortcode extends ChainInheritance(OrmModel, []) {
  static entity = 'shortcode'

  static fields () {
    return {
      documentId: this.attr(null),
      token: this.attr(null)
    }
  }

  static apiConfig = {
    get actions () {
      const configActions = Object.assign({}, Model.apiConfig.actions)
      return configActions
    }
  }
}
