<template lang="pug">
  div
    v-navigation-drawer(
      v-model="drawer"
      :color="$vuetify.theme.currentTheme.drawer"
      class="drawer"
      app
      id="app-sidebar"
      permanent
      clipped
      width="200"
      fixed
      floating
    )
      template(v-slot:prepend)
        div(class="d-flex align-center justify-center mb-10")
          nuxt-link(to="/documents" exact)
            v-img(
              :src="require('~/assets/images/logo/logo-black.svg')"
              max-width="152"
            )
      e-button-template-upload(v-if="$route.name === 'templates'")
      e-button-document-upload(v-else)
      v-list
        v-list-item-group(v-model="activeLink")
          template(v-for="(link, index) in items")
            nuxt-link(:to="link.href" class="d-flex align-center link" exact active-class="link--active")
              v-list-item(v-if="isVisible(link)" :key="index" class="list-item")
                v-list-item-icon(class="mr-0")
                  e-svg-icon(:name="isRouteActive(link.href) ? link.activeIcon : link.icon")
                v-list-item-title(class="link__text pl-3") {{ $t(link.text) }}
                span(v-if="link.isNew" class="link__new") New
          v-btn(class="tags-btn" @click="createTag")
            v-list-item-title(class="text-left fw-500") {{ $t('Tags') }}
            v-list-item-action
              e-svg-icon plus-black
          template(v-for="(tag, index) in tags")
            v-hover(v-slot="{ hover }" :key="tag.id")
              v-list-item(class="list-item" @click="onTagClick(tag.id)")
                v-list-item-icon(class="ma-0 d-flex justify-center align-center align-self-center h-100")
                  div(class="tag-circle" :style="{ backgroundColor: tag.color }")
                v-list-item-title(class="link__text pl-3") {{ tag.name }}
                v-list-item-action(v-if="hover || onHoverTags[tag.id]" class="ma-0 pr-3")
                  e-tags-menu-actions(
                    :value="onHoverTags[tag.id]"
                    :visibility-tags="visibilityTags"
                    :tag="tag"
                    @input="onHoverTags[tag.id] = !onHoverTags[tag.id]"
                    @onSettings="onTagSettingsClick"
                    @updateVisibility="updateTagVisibility"
                  )
</template>

<script>
import { menuItems } from '~/const/menuItems'
import ESvgIcon from '~/components/elements/icons/e-svg-icon'
import responsive from '~/mixins/pages/responsive'
import EButtonBurger from '~/components/elements/buttons/e-button-burger'
import ELink from '~/components/elements/links/e-link'
import EButtonDocumentUpload from '~/modules/documents/components/elements/e-button-document-upload'
import tags from '~/modules/tags/mixins/tags'
import ETagsMenuActions from '~/modules/tags/views/components/e-tags-menu-actions'
import EButtonTemplateUpload from '~/modules/templates/components/elements/e-button-template-upload'
import checkVisibility from '~/mixins/checkVisibility'

export default {
  components: {
    ETagsMenuActions,
    EButtonDocumentUpload,
    EButtonTemplateUpload,
    EButtonBurger,
    ESvgIcon,
    ELink
  },
  mixins: [checkVisibility, tags, responsive],
  data: () => ({
    drawer: true,
    activeLink: null,
    onHoverTags: {},
    visibilityTags: {},
    menu: false
  }),
  computed: {
    items () {
      return menuItems
    }
  },
  watch: {
    tags (val) {
      if (val) {
        this.setActiveLink()
        for (const tag of this.tags) {
          this.$set(this.onHoverTags, tag.id, false)
          this.$set(this.visibilityTags, tag.id, tag.showInDocuments)
        }
      }
    }
  },
  created () {
    this.getTags()
  },
  methods: {
    isRouteActive (path) {
      return this.$route.path === path
    },
    setActiveLink () {
      const tagsPath = this._.map(this.tags, tag => ({ href: `/tags/${tag.id}` }))
      const tagsAndMenuItems = this._.concat(this.items, tagsPath)
      const activeLink = this._.findIndex(tagsAndMenuItems, item => this.isRouteActive(item.href))
      if (activeLink === -1) {
        this.activeLink = null
      } else {
        this.activeLink = activeLink
      }
    },
    onTagClick (tagId) {
      this.$router.push({ name: 'tags-id', params: { id: tagId } })
    },
    onTagSettingsClick (tagId) {
      this.onHoverTags[tagId] = true
    },
    updateTagVisibility (tagId, val) {
      this.visibilityTags[tagId] = val
    }
  }
}
</script>

<style lang="scss">
.drawer {
  padding: 24px 8px;
  border: none !important;
}

.link {
  width: 100%;
  text-decoration: none !important;

  &--active {
    border-radius: 12px;
    color: rgba(0, 0, 0, 0.4) !important;
  }

  &__text {
    font-size: 16px !important;
    line-height: 20px !important;
    font-weight: 400 !important;
    color: #000;
  }

  &__new {
    padding: 0 6px;
    border-radius: 20px;
    border: 2px solid $primary;
    background: rgba(143, 37, 125, 0.06);

    color: $primary;

    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
  }
}

.list-item {
  height: 48px;
  border-radius: 12px;

  &:before {
    border-radius: 12px;
  }
}

.tag-circle {
  width: 12px;
  height: 12px;
  border-radius: 50%;
}

.tags-btn {
  margin-top: 16px;
  width: 100%;
  border-radius: 12px;
  min-height: 48px;
  background: none !important;

  &:before {
    border-radius: 12px;
  }
}
</style>
