<template lang="pug">
  v-app-bar(
    app
    :color="$vuetify.theme.currentTheme['app-bar']"
    clipped-left
    elevation="0"
  )
    div(class="d-flex align-center justify-space-between w-100")
      nuxt-link(to="/documents" exact)
        v-img(
          max-width="152"
          :src="require('~/assets/images/logo/logo-black.svg')"
        )
      div(class="d-flex align-center")
        m-support-buttons
        v-btn(
          icon
          @click="$emit('toggleDrawer')"
          height="40"
          width="40"
          class="header-mobile__toggle"
        )
          v-img(
            v-if="!isDrawerVisible"
            :src="require('~/assets/images/person.png')"
            max-width="16"
          )
          v-icon(v-else) mdi-close
</template>

<script>
import MSupportButtons from '~/components/modules/m-support-buttons'

export default {
  components: {
    MSupportButtons
  },
  props: {
    isDrawerVisible: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped lang="scss">
.header-mobile {
  &__toggle {
    background: rgba(0, 0, 0, 0.03) !important;
  }
}
</style>
