<template lang="pug">
  div
    v-text-field(v-bind="$attrs" v-on="$listeners"
              :append-icon="appendIcon ? (visible ? 'mdi-eye-off' : 'mdi-eye') : ''"
              :type="visible ? 'text' : 'password'"
              @click:append="visible = !visible")
    password(
      :value="$attrs.value || ''"
      :strength-meter-only="true"
      v-if="showPasswordMeter"
    )
</template>

<script>
import { VTextField } from 'vuetify/lib'
import Password from 'vue-password-strength-meter'

export default {
  components: {
    VTextField,
    Password
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false
    },
    appendIcon: {
      type: Boolean,
      default: true
    },
    showPasswordStrength: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      visible: this.isVisible
    }
  },
  computed: {
    showPasswordMeter () {
      return this.showPasswordStrength && !this._.get(this.$attrs, 'error-messages', []).length
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  .Password {
    max-width: 98%;
    margin: 0 auto;
    transform: translateY(-18px);
    height: 0;

    &__strength-meter {
      margin: 0 auto;

      &--fill[data-score="0"],
      &--fill[data-score="1"] {
        background: #F03A47;
      }

      &--fill[data-score="2"] {
        background: #F5B841;
      }

      &--fill[data-score="3"] {
        background: #FFF07C;
      }

      &--fill[data-score="4"] {
        background: #B0F2B4;
      }
    }
  }
}
</style>
