<template lang="pug">
  v-btn(
    depressed
    :type="type"
    :class="getCls"
    v-bind="$attrs"
    :style="{borderRadius: `${borderRadius}px`}"
    :loading="loading"
    @click="$emit('click', $event)"
  )

    e-svg-icon(
      v-if="icon && !showCustomIcon"
      :name="activeIcon && isActive ? activeIcon : icon"
      class="mr-2 custom-icon"
    )
    template(v-else)
        slot(name="custom-icon")
    slot
      | Переглянути

</template>

<script>
import { VBtn } from 'vuetify/lib'
import ESvgIcon from '~/components/elements/icons/e-svg-icon'
import EFlatIcon from '~/components/elements/icons/e-flat-icon'

export default {
  name: 'EButtonMain',
  components: {
    ESvgIcon,
    EFlatIcon,
    VBtn
  },
  props: {
    isActive: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String,
      default: ''
    },
    activeIcon: {
      type: String,
      default: ''
    },
    showCustomIcon: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'button',
      validator: (value) => {
        return ['button', 'submit'].includes(value)
      }
    },
    size: {
      type: String,
      default: 'md',
      validator: (value) => {
        return ['sm', 'md', 'lg'].includes(value)
      }
    },
    loading: {
      type: Boolean,
      default: false
    },
    textColor: {
      type: String,
      default: 'main'
    },
    appearance: {
      type: String,
      default: 'primary',
      validator: (value) => {
        return ['primary', 'secondary', 'empty', 'gray'].includes(value)
      }
    },
    borderRadius: {
      type: String,
      default: '12'
    }
  },
  computed: {
    isOutlined () {
      return 'outlined' in this.$attrs
    },
    isPlain () {
      return 'plain' in this.$attrs
    },
    getCls () {
      return [
        'e-button-main',
        `e-button-main--${this.size}`,
        this.isOutlined || this.isPlain ? '' : `e-button-main--${this.appearance}`,
        `e-button-main--text-${this.textColor}`,
        this.isPlain ? 'e-button-main--plain' : '',
        this.isActive ? `e-button-main--${this.appearance}-active` : ''
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.e-button-main {
  white-space: normal;
  padding: 5px 18px !important;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  border-radius: $btn-border-radius;

  &.v-btn--disabled {
    background: linear-gradient(247.21deg, rgba(140, 226, 176, 0.8) 8.73%, rgba(4, 174, 213, 0.8) 90.64%) !important;
    color: rgba(255, 255, 255, 0.7) !important;
  }

  &--primary {
    background: $primary !important;
  }

  &--secondary {
    background: $btn-secondary-color !important;
  }

  &--empty {
    background: none;

    &-active {
      outline: 2px solid $primary;
      color: $primary !important;
    }
  }

  &--gray {
    background: #F0F0F0;
  }

  &--text-main {
    color: $btn-main-color;
  }

  &--text-secondary {
    color: $btn-secondary-color;
  }

  &--text-primary {
    color: $primary;
  }

  &--text-danger {
    color: $danger;
  }

  &--plain {
    background: none !important;
  }

  &--sm {
    height: 40px !important;
  }
  &--md {
    height: 48px !important;
  }
  &--lg {
    height: 52px !important;
  }
}
</style>
