<template lang="pug">
  ValidationProvider(:rules="validationRules" name="uploader" ref="uploader")
    v-btn(
      :id="buttonId"
      class="w-100 mb-6"
      :class="[secondaryStyle ? 'secondary-button' : 'main-button']"
      :loading="loading"
      @click="openFileMenu"
    ) {{ $t(buttonText) }}
    v-file-input(
      v-show="false"
      ref="file"
      type="file"
      :multiple="multiple"
      class="e-input-file"
      :accept="accept"
      @change="$emit('onload', $event)"
    )
</template>

<script>
export default {
  name: 'EButtonUploadFile',
  props: {
    // todo: shouldn`t be here
    gtmEventName: {
      type: String,
      default: 'click_download'
    },
    buttonId: {
      type: String,
      default: 'upload-button'
    },
    buttonText: {
      type: String,
      default: 'Download'
    },
    validationRules: {
      type: String,
      default: null
    },
    accept: {
      type: String,
      default: null
    },
    multiple: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    secondaryStyle: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    openFileMenu () {
      if (this.gtmEventName) {
        window.dataLayer && window.dataLayer.push({
          event: this.gtmEventName,
          email: this._.get(this.$User, 'email')
        })
      }
      this.$refs.file.$refs.input.click()
    },
    async validate (files) {
      return await this.$refs.uploader.validate(files)
    }
  }
}
</script>

<style scoped lang="scss">

</style>
