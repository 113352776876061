<template lang="pug">
  div(
    :class="['notifications-list-item', `notifications-list-item--${item.priority}`, item.viewed ? 'notifications-list-item--viewed' : '']"
    @click="openNotification"
  )
    div(class="notifications-list-item__header")
      h4(class="notifications-list-item__title") {{ item.title }}
      p(class="notifications-list-item__date") {{ formattedDate(item.date) }}
    div(
      class="notifications-list-item__text"
      v-html="item.message"
    )
</template>

<script>
import { formats } from '~/const/global'
import contentDialog from '~/mixins/dialogs/contentDialog'

export default {
  name: 'BlockNotificationsListItem',
  mixins: [contentDialog],
  props: {
    item: {
      type: Object,
      default: () => {}
    }
  },
  methods: {
    formattedDate (date) {
      return this.$moment(new Date(date)).format(formats.dateTimeWithoutSeconds)
    },
    async openNotification () {
      this.$emit('notificationOpen', this.item)
      await this.contentDialog.open({
        component: 'block-single-notification',
        width: '500px',
        componentProps: {
          title: this._.get(this.item, 'title', ''),
          message: this._.get(this.item, 'message', '')
        }
      })
      this.$emit('notificationClose', this.item)
    }
  }
}
</script>

<style scoped lang="scss">
.notifications-list-item {
  border-radius: 10px;
  padding: 10px 15px;
  cursor: pointer;
  position: relative;
  transition: 0.3s;
  height: auto;
  border: 1px solid rgba(0, 0, 0, 0.07);
  background-color: rgba(0, 0, 0, 0.07);
  opacity: 1;

  &:hover {
    background-color: rgba(0, 0, 0, 0.12);
    border-color: rgba(0, 0, 0, 0.12);
  }

  &--high {
    border-color: rgba(255, 0, 43, 0.6);

    &:hover {
      border-color: rgba(255, 0, 43, 0.6);
    }
  }

  &--viewed {
    opacity: 0.4;
    border-color: rgba(0, 0, 0, 0.07);
  }

  &__title {
    margin: 0;
    font-weight: 400;
    font-size: 0.875rem;
  }

  &__text {
    margin: 7px 0 0;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: 0.875rem;
    max-height: 22px;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    @media (min-width: map-get($breakpoints, 'sm')) {
      flex-direction: row;
      gap: 15px;
    }
  }

  &__date {
    font-size: 0.675rem;
    margin: 0;
  }
}
</style>
