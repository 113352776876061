<template lang="pug">
  div(class="documents-list")
    div(
      v-for="document in documents"
      :key="document.id"
      class="documents-list__item"
    )
      div(class="d-flex align-start")
        div(class="pt-2")
          div(class="documents-list__item-dot" :class="{ 'filled': isPassedDocument(document) }")
        div(class="pl-6 text-left")
          div(class="documents-list__item-title") {{ document.title }}
          div(
            class="documents-list__item-reason"
            :class="[isErrorDocument(document) ? 'error-text' : 'skipped']"
          ) {{ getDocumentErrorMessage(document) }}
      div(class="documents-list__item-icon")
        template(v-if="getDocumentLoading(document)")
          span(class="documents-list__item-reason signing") {{ $t('Signing in the process') }}
          v-progress-circular(width="2" size="20" color="#8F257D" :indeterminate="true")
        template(v-else-if="isErrorDocument(document)")
          e-svg-icon close-circle-red
        template(v-else-if="isSkippedDocument(document)")
          v-tooltip(bottom)
            template(#activator="{ on }")
              div(v-on="on" class="d-flex align-center")
                span(class="documents-list__item-reason skipped") {{ $t('Signed before') }}
                e-svg-icon alert-triangle
            span {{ $t('Document has signed already') }}
        template(v-else-if="isSignedDocument(document)")
          e-svg-icon check-circle-green
        template(v-else)
          v-progress-circular(width="2" size="20")
</template>

<script>
import ESvgIcon from '~/components/elements/icons/e-svg-icon'

export default {
  name: 'BlockDocumentsSignList',
  components: {
    ESvgIcon
  },
  props: {
    documents: {
      type: Array,
      default: () => []
    },
    documentsStates: {
      type: Object,
      default: () => ({})
    },
    statuses: {
      type: Object,
      default: () => ({})
    }
  },
  methods: {
    isPassedDocument (document) {
      return this._.get(this.documentsStates[document.id], 'passed')
    },
    isSignedDocument (document) {
      return this._.get(this.documentsStates[document.id], 'status') === this.statuses.signed
    },
    isErrorDocument (document) {
      return this._.get(this.documentsStates[document.id], 'status') === this.statuses.error
    },
    isSkippedDocument (document) {
      return this._.get(this.documentsStates[document.id], 'status') === this.statuses.skipped
    },
    getDocumentLoading (document) {
      return this._.get(this.documentsStates[document.id], 'loading')
    },
    getDocumentErrorMessage (document) {
      return this._.get(this.documentsStates[document.id], 'error.message')
    }
  }
}
</script>

<style scoped lang="scss">
.documents-list {
  &__item {
    display: flex;
    align-items: center;
    justify-content: space-between;

    padding: 8px 0;

    &:not(:last-child) {
      margin-bottom: 4px;
    }

    &-dot {
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background: #F0F1F2;

      &.filled {
        background: #000000;
      }
    }

    &-title {
      color: $text-dark;
      font-variant-numeric: slashed-zero;
      font-size: 14px;
      font-weight: 300;
      line-height: 24px;
    }

    &-reason {
      font-size: 12px;
      font-weight: 400;
      line-height: 20px;
      padding-right: 8px;

      &.signing {
        color: $primary;
      }
      &.skipped {
        color: #D2AE22;
      }
      &.error-text {
        color: $danger;
      }
    }
  }
}
</style>
