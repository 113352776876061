import auth from '~/modules/auth/auth'

export default function ({ store, route, redirect }) {
  const guestToken = route.query?.token
  const ref = route.query?.ref
  if (guestToken) {
    return
  }
  if (!auth.isAuthenticated()) {
    const from = store?.$router?.history?.current?.path
    return redirect({
      path: '/auth',
      query: {
        from,
        ref
      }
    })
  }
}
