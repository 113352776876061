import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';
import { VTabsSlider } from 'vuetify/lib/components/VTabs';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCol,{staticClass:"py-0 m-group-tabs",attrs:{"cols":"12"}},[_c(VTabs,{attrs:{"height":"40px","grow":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c(VTabsSlider,{attrs:{"color":_vm.invalidTabs.includes(_vm.tab)? 'error': 'primary'}}),_vm._l((_vm.items),function(item,key){return _c(VTab,{key:item.name,class:_vm.invalidTabs.includes(key) ? 'error--text' : '',attrs:{"color":"red"}},[_vm._v(_vm._s(_vm.$t(item.text)))])})],2),_c(VTabsItems,{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},_vm._l((_vm.items),function(item){return _c(VTabItem,{key:item.name},[_c(VCard,{attrs:{"flat":""}},[_c(VCardText,{staticClass:"px-0 py-7"},[_c(VRow,[_vm._t("default",null,{"fields":_vm.tabFields(item),"tab":item})],2)],1)],1)],1)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }