import VuexORMConnector from '~/plugins/vuex-orm/plugins/_drivers/VuexORMConnector'

const rows = {
  computed: {
    rows () {
      let items = []
      const params = {
        ...this.options,
        filterBy: this.getLocalFilters()
      }

      if (this.model.paginated) {
        params.sortBy = this.relatedSortBy()
      }

      const connector = (new VuexORMConnector(this.model.query(), params))

      // TODO: configurre for always loading of relations (based on edited fields ?).
      if (this.model.ormLoadWithRelations) {
        // TODO potentially slow place
        items = connector.query.withAllRecursive(2).get()
      } else {
        items = connector.query.get()
      }

      if (this.itemsFilter) {
        for (const key in this.itemsFilter) {
          items = connector.query.where((item) => {
            if (item[key]) {
              if (this._.get(item[key], 'id', null)) {
                return this._.get(item[key], 'id', null) === this._.get(this.itemsFilter[key], 'id', null)
              }
              return item[key] === this.itemsFilter[key]
            }
            return item
          }).get()
        }
      }

      return connector.manualSort(items)
    }
  }
}

export default rows
