<template lang="pug">
  div(class="notifications-list")
    div(
      v-if="loading"
      class="notifications-list__loader"
    )
      e-progress-circular(
        size="xl"
        width="4"
      )
    div(
      v-else-if="noData"
      class="notifications-list__no-data-text"
    ) {{ $t(noDataText) }}
    div(
      class="h-100"
      v-else
    )
      div(:class="{ 'notifications-list__items': true, 'notifications-list__items--fixed-height': loadMoreBtn }")
        block-notifications-list-item(
          v-for="(item, index) in items"
          :key="index"
          :item="item"
          @notificationOpen="handleNotificationOpen"
          @notificationClose="handleNotificationClose"
        )
      v-btn(
        v-if="loadMoreBtn && canLoadMore"
        @click="loadMoreNotifications"
        :loading="loadMoreLoading"
        class="mt-5 d-block mx-auto"
        small
        text
      )
        e-svg-icon(
          class="mr-2"
          size="sm"
        ) refresh
        span {{ $t('Load more') }}
</template>

<script>
import BlockNotificationsListItem from '~/modules/notifications/views/components/block-notifications-list-item'
import Notifications from '~/modules/notifications/models/Notifications'
import EProgressCircular from '~/components/elements/progress/e-progress-circular'
import ESvgIcon from '~/components/elements/icons/e-svg-icon'

export default {
  name: 'BlockNotificationsList',
  components: {
    EProgressCircular,
    BlockNotificationsListItem,
    ESvgIcon
  },
  props: {
    queryFilter: {
      type: Object,
      default: () => {}
    },
    onNotificationOpen: {
      type: Function,
      default: () => {}
    },
    onNotificationClose: {
      type: Function,
      default: () => {}
    },
    onNotificationRead: {
      type: Function,
      default: () => {}
    },
    noDataText: {
      type: String,
      default: 'There are no notifications yet'
    },
    loadMoreBtn: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    items: [],
    offset: 1,
    canLoadMore: false,
    loading: false,
    loadMoreLoading: false
  }),
  computed: {
    model () {
      return Notifications
    },
    noData () {
      return !this.items.length
    }
  },
  created () {
    this.getAllNotifications(this.queryFilter)
    this.$gtm.push({
      event: 'view_notifications',
      organization: this._.get(this.$Organization, 'owner.email')
    })
  },
  methods: {
    async getNotifications (filter = {}) {
      try {
        const res = this._.get(await this.model.api().filter({
          limit: 10,
          offset: this.offset,
          ...filter
        }).all({}, {
          route: this.model.$routes[this.model.entity].userList()
        }), 'response.data')
        this.items = [
          ...this.items,
          ...this._.get(res, 'data', [])
        ]
        this.canLoadMore = this._.get(res, 'meta.totalItems') > this.items.length
      } catch (e) {
        this.$handlers.error(e, this)
      }
    },
    async getAllNotifications (filter = {}) {
      try {
        this.loading = true
        await this.getNotifications(filter)
      } catch (e) {
        this.$handlers.error(e, this)
      } finally {
        this.loading = false
      }
    },
    async loadMoreNotifications () {
      try {
        this.loadMoreLoading = true
        this.offset += 1
        await this.getNotifications()
      } catch (e) {
        this.$handlers.error(e, this)
      } finally {
        this.loadMoreLoading = false
      }
    },
    async handleNotificationOpen (item) {
      this.$emit('notificationOpen', item)
      await this.handleReadNotification(item)
      if (this._.isFunction(this.onNotificationOpen)) {
        await this.onNotificationOpen(item, this)
      }
    },
    async handleNotificationClose (item) {
      this.$emit('notificationClose', item)
      if (this._.isFunction(this.onNotificationClose)) {
        await this.onNotificationClose(item, this)
      }
    },
    async handleReadNotification (item) {
      try {
        const itemId = this._.get(item, 'id')
        await this.model.api().readNotification(itemId)
        if (!item.viewed) {
          this._.each(this.items, (item) => {
            if (this._.get(item, 'id') === itemId) {
              item.viewed = true
            }
          })
          if (this._.isFunction(this.onNotificationRead)) {
            await this.onNotificationRead(item)
          }
        }
        this.$gtm.push({
          event: 'notification_read',
          payload: item,
          organization: this._.get(this.$Organization, 'owner.email')
        })
      } catch (e) {
        this.$handlers.error(e, this)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.notifications-list {
  width: 100%;
  height: 100%;

  &__items {
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 100%;
    padding-right: 5px;
    margin-right: -5px;

    &--fixed-height {
      overflow-y: auto;
      max-height: calc(100% - 48px);
    }
  }

  &__loader {
    width: 100%;
    min-width: 200px;
    height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__no-data-text {
    font-size: 0.875rem;
    text-align: center;
  }
}
</style>
