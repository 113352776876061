<template lang="pug">
  div(class="socials")
    e-link(
      v-for="(social, idx) in socials"
      :key="idx"
      :href="social.href"
      class="socials__item"
    )
      e-svg-icon(:name="social.icon" :size="xsDevice ? 'lg': 'md'")
      span(v-if="xsDevice" class="text-14px dark pl-4") {{ $t(social.text) }}
</template>

<script>
import ELink from '~/components/elements/links/e-link'
import ESvgIcon from '~/components/elements/icons/e-svg-icon'
import responsive from '~/mixins/pages/responsive'

export default {
  name: 'BlockProfileSocials',
  components: {
    ELink,
    ESvgIcon
  },
  mixins: [responsive],
  computed: {
    socials () {
      return [
        {
          icon: 'facebook-1',
          href: 'https://www.facebook.com/profile.php?id=61561763253397',
          text: 'Join us in Facebook'
        },
        {
          icon: 'instagram-1',
          href: 'https://www.instagram.com/dubidoc.ua/',
          text: 'Join us in Instagram'
        },
        {
          icon: 'telegram',
          href: 'https://t.me/dubidoc',
          text: 'Our news in Telegram'
        },
        {
          icon: 'linkedin',
          href: 'https://www.linkedin.com/company/dubidoc-ua/about/',
          text: 'Join us in Linkedin'
        },
        {
          icon: 'youtube',
          href: 'https://www.youtube.com/@Dubidoc',
          text: 'Watch us in Youtube'
        }
      ]
    }
  }
}
</script>

<style scoped lang="scss">
.socials {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 16px;

  @media (max-width: map-get($breakpoints, 'xs')) {
    flex-direction: column;
    align-items: flex-start;
    gap: 0;
  }

  &__item {
    height: 52px !important;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (min-width: map-get($breakpoints, 'sm')) {
      width: 32px;
      height: 32px !important;

      background: rgba(18, 18, 18, 0.03);

      border-radius: 50%;
    }
  }
}
</style>
