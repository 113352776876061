<template lang="pug">
  div
    information-snackbar
    success-snackbar
</template>

<script>
import InformationSnackbar from '~/modules/snackbar/views/components/information-snackbar'
import SuccessSnackbar from '~/modules/snackbar/views/components/success-snackbar'
export default {
  name: 'Snackbars',
  components: {
    SuccessSnackbar,
    InformationSnackbar
  }
}
</script>

<style scoped lang="scss">

</style>
