import { Model } from '@vuex-orm/core'
import OrmModel from '~/models/abstracts/base/OrmModel'

export default class Tag extends OrmModel {
  static entity = 'tag'

  static fields () {
    return {
      id: this.attr(null),
      name: this.attr(null),
      color: this.attr(null),
      showInDocuments: this.attr(null)
    }
  }

  static apiConfig = {
    get actions () {
      const configActions = Object.assign({}, Model.apiConfig.actions)

      return configActions
    }
  }
}
