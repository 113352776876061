import { Model } from '@vuex-orm/core'
import jwtDecode from 'jwt-decode'

export default class AuthToken extends Model {
  static entity = 'authtoken'
  static primaryKey = 'userName'

  static userTypes = {
    SUPER_ADMIN: 'superadmin',
    USER: 'user',
    GUEST: 'guest'
  }

  static fields () {
    return {
      token: this.attr(null),
      refresh_token: this.attr(null),
      createdAt: this.attr(null),
      expiredAt: this.attr(null),
      userName: this.attr(null),
      // TODO temporary field
      userType: this.attr(null)
    }
  }

  toString () {
    return this.token
  }

  static apiConfig = {
    actions: {
      /* TODO for now it important to have only one token for current user
       in future it will be possible to add more than one token */
      getToken (login, code, referralLink) {
        const config = {}
        if (referralLink) {
          config.headers = { 'X-Referrer-Key': referralLink }
        }
        return this.post(Model.$routes.auth.get_token(), { login, code }, {
          persistBy: 'create',
          dataTransformer: ({ data }) => {
            if (data.token) {
              try {
                const tokenData = jwtDecode(data.token)
                data.userName = tokenData.username
                data.userType = AuthToken.userTypes.USER
                data.createdAt = tokenData.iat
                data.expiredAt = tokenData.exp
              } catch (e) {

              }
            }
            return data
          },
          ...config
        })
      },
      // This action is available only for superAdmin user
      getTokenForUser (userId) {
        return this.get(Model.$routes.users.getAuthToken(userId), {
          persistBy: 'create',
          dataTransformer: ({ data }) => {
            if (data.token) {
              try {
                const tokeData = jwtDecode(data.token)
                data.userName = tokeData.username
                data.userType = AuthToken.userTypes.USER
                data.createdAt = tokeData.iat
                data.expiredAt = tokeData.exp
              } catch (e) {

              }
            }
            return data
          }
        })
      },
      refresh (refreshToken) {
        return this.post(Model.$routes.auth.refresh_token(), { refresh_token: refreshToken }, {
          persistBy: 'create',
          dataTransformer: ({ data }) => {
            if (data.token) {
              try {
                const tokeData = jwtDecode(data.token)
                data.userName = jwtDecode(data.token).username
                data.userType = AuthToken.userTypes.USER
                data.createdAt = tokeData.iat
                data.expiredAt = tokeData.exp
              } catch (e) {

              }
            }
            return data
          }
        })
      },
      getCode (login) {
        return this.post(Model.$routes.auth.get_code(), { login })
      }
    }
  }

  refreshToken () {
    return AuthToken.api().refreshToken(this.refreshToken)
  }
}
