import EdsKeyData from '~/services/EdsKey/EdsKeyData'
import EdsIitCheckboxModel from '~/modules/eds-iit-checkbox/models/EdsIitCheckbox'

const edsIitCheckboxHelper = {
  methods: {
    async edsIitCheckboxFallback (type) {
      const modelInstance = EdsIitCheckboxModel.query().where('type', type).first()
      if (modelInstance) {
        await modelInstance.delete()
      }
    },
    async generateSignInterfacePayloadAndErrorsCheck ({
      keyCheck = true,
      signInterface,
      errorFallback,
      type = 'edsIitCheckboxForOrganization'
    }) {
      const keyData = this._.get(signInterface, 'keyData', null)
      const sign = this._.get(signInterface, 'sign')
      const edsKeyData = new EdsKeyData(keyData)
      const isDiia = signInterface?.isDiia
      const isSmartId = signInterface?.isSmartId
      const isDepositSign = signInterface?.isDepositSign
      let error = false
      let noReturn = false

      if (keyCheck && !isDiia) {
        if (!signInterface) {
          if (typeof errorFallback === 'function') {
            await errorFallback()
          }
          error = true
          noReturn = true
        } else if (!keyData) {
          this.$handlers.error('We could not read the key data. Please try again or choose a different key', this)
          await this.edsIitCheckboxFallback(type)
          if (typeof errorFallback === 'function') {
            await errorFallback()
          }
          error = true
        } else if (edsKeyData.isSeal()) {
          this.$handlers.error('Please use the EDS key instead of seal', this)
          await this.edsIitCheckboxFallback(type)
          if (typeof errorFallback === 'function') {
            await errorFallback()
          }
          error = true
        } else if (edsKeyData.identity !== this._.get(this.$Organization, 'edrpou', null)) {
          this.$handlers.error('The USREOU codes do not match. Please choose another', this)
          await this.edsIitCheckboxFallback(type)
          if (typeof errorFallback === 'function') {
            await errorFallback()
          }
          error = true
        }
      }

      return {
        sign,
        edsKeyData,
        error,
        noReturn,
        isDiia,
        isSmartId,
        isDepositSign,
        dialogClosed: !signInterface
      }
    }
  }
}

export default edsIitCheckboxHelper
