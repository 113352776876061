import { VBtn } from 'vuetify/lib/components/VBtn';
import { VForm } from 'vuetify/lib/components/VForm';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VForm,{staticClass:"organizations-users-add"},[_c('h2',{staticClass:"title-32px mb-2"},[_vm._v(_vm._s(_vm.$t('New users')))]),_c('p',{staticClass:"text-14px"},[_vm._v(_vm._s(_vm.$t('Enter your colleagues` email and assign them a role, and we`ll send a message telling them what to do next. You can always change access in the settings section')))]),_c('ValidationObserver',{ref:"form"},[_vm._l((_vm.users),function(user,idx){return _c('div',{key:idx,staticClass:"organizations-users-add__item",class:{ 'with-delete': _vm.users.length > 1 }},[_c('ValidationProvider',{attrs:{"name":_vm.$t('Email'),"rules":"required|email","mode":"lazy"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c(VTextField,{attrs:{"label":_vm.$t('Email'),"error-messages":errors,"filled":""},model:{value:(_vm.users[idx].email),callback:function ($$v) {_vm.$set(_vm.users[idx], "email", $$v)},expression:"users[idx].email"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":_vm.$t('Role in system'),"rules":"required","mode":"lazy"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c(VSelect,{attrs:{"items":_vm.userRoles,"label":_vm.$t('Role in system'),"error-messages":errors,"menuProps":{ bottom: true, offsetY: true },"filled":""},model:{value:(_vm.users[idx].role),callback:function ($$v) {_vm.$set(_vm.users[idx], "role", $$v)},expression:"users[idx].role"}})]}}],null,true)}),(_vm.users && _vm.users.length > 1)?_c(VBtn,{staticClass:"organizations-users-add__item-delete",attrs:{"height":"48","color":"#FF17440F"},on:{"click":function($event){return _vm.deleteUser(idx)}}},[_c('e-svg-icon',[_vm._v("danger-trash")])],1):_vm._e()],1)}),_c('div',{staticClass:"d-flex align-center pb-8"},[_c(VSwitch,{staticClass:"mt-0 mr-2",attrs:{"hide-details":""},model:{value:(_vm.notifyUsers),callback:function ($$v) {_vm.notifyUsers=$$v},expression:"notifyUsers"}}),_c('span',{staticClass:"text-14px dark pt-1"},[_vm._v(_vm._s(_vm.$t('Send emails about documents')))])],1)],2),_c('div',{staticClass:"organizations-users-add__actions"},[_c(VBtn,{staticClass:"secondary-button",on:{"click":_vm.addUser}},[_vm._v(_vm._s(_vm.$t('Add one more user')))]),_c(VBtn,{staticClass:"main-button",attrs:{"loading":_vm.isLoading},on:{"click":_vm.submit}},[_vm._v(_vm._s(_vm.$t('Send messages')))])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }