import auth from '~/modules/auth/auth'
import routes from '~/const/routes'

export default ({ $axios, redirect, app, route }) => {
  // route will not change on nuxt router change, it will only change on window location change
  let refreshing = false
  const CancelToken = $axios.CancelToken
  const delegatedError = (response) => {
    const err = new Error()
    err.response = response
    return err
  }

  const isRefreshing = () => {
    return new Promise((resolve, reject) => {
      const interval = setInterval(() => {
        const token = auth.getAuthToken()
        if (!token || !refreshing) {
          clearInterval(interval)
          resolve(true)
        }
      }, 300)
    })
  }
  const logOut = async () => {
    if (auth.getToken()) {
      await auth.logout()
      redirect('/auth')
      throw new Error('Auth token is outdated. Renew Please')
    }
  }
  const isPublicRoute = (url, shortcodeId = null, templateId = null) => {
    return [
      ...Object.values(routes.auth).map(item => item()),
      ...Object.values(routes.shortcode).map(item => item(shortcodeId)),
      routes.template.concrete(templateId),
      routes.template.toDocument(templateId),
      routes.login()
    ].includes(url)
  }

  $axios.defaults.validateStatus = (status) => {
    return [200, 201, 202, 203, 204].includes(status)
  }

  $axios.onRequest(async (request) => {
    const source = CancelToken.source()
    const token = auth.getAuthToken()
    const guestToken = auth.getGuestAuthToken()
    request.cancelToken = source.token
    // let /open/{id} access page for unauthorized users
    const shortcodeId = ['open-id', 'show-id'].includes(route.name) ? route.params.id : null
    const templateId = route.name === 'templates-id' ? route.params.id : null
    if (!isPublicRoute(request.url, shortcodeId, templateId)) {
      if (guestToken) {
        request.headers['X-Auth-Token'] = guestToken.token
        request.headers['X-Client-Name'] = 'web'
        request.headers['Accept-Language'] = app.i18n.locale
        return request
      }

      if (token && !refreshing && (token.expiredAt - Math.floor(Date.now() / 1000) < 2)) {
        try {
          refreshing = true
          await auth.refreshToken()
        } catch (e) {
          await logOut()
        } finally {
          refreshing = false
        }
      }

      await isRefreshing()
      if (!auth.getToken()) {
        source.cancel('Auth token is outdated. Renew Please')
      }

      request.headers.Authorization = auth.getBearer()
    }

    request.headers['X-Client-Name'] = 'web'
    request.headers['X-ORGANIZATION'] = auth.getCurrentOrganizationId()
    request.headers['Accept-Language'] = app.i18n.locale

    if (token && !refreshing && !(token.expiredAt - Math.floor(Date.now() / 1000) < 2)) {
      request.headers.Authorization = auth.getBearer()
    }

    return request
  })

  $axios.onError((data) => {
    const response = data.response
    if (response) {
      if (!isPublicRoute(response.config.url) && response.config.url !== '/token/refresh' && response.status === 401 &&
        !/Processing:/.test(response.data.detail)) {
        // TODO hotfix
        logOut()
        response.data.message = 'Auth token is outdated. Renew Please'
      }
      if (response.data) {
        throw delegatedError(response)
      }
    }
  })

  $axios.onResponseError(({ response }) => {
    // Executes after onError.
  })

  $axios.onResponse((response) => {
    return response
  })
}
