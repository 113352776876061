import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,_vm._g(_vm._b({attrs:{"content-class":_vm.contentClass,"persistent":_vm.persistent,"scrollable":true}},'v-dialog',_vm.$attrs,false),_vm.$listeners),[(_vm.$attrs.value)?_c(VCard,{class:_vm.cardClass,attrs:{"elevation":"0"}},[(_vm.loading)?_c('e-overlay-block',{attrs:{"loading":true,"progress-size":"xl","progress-width":"5","absolute":""}}):_vm._e(),(_vm.showCloseBtn)?_c('e-button-close',{staticClass:"t-dialog__close-btn",attrs:{"x-small":""},on:{"click":_vm.closeBtnClick}}):_vm._e(),(('title' in _vm.$slots) || 'subtitle' in _vm.$slots)?_c(VCardTitle,{class:{ 'mb-3': 'title' in _vm.$slots }},[_c(VLayout,{attrs:{"wrap":"","align-center":""}},[_c(VFlex,{attrs:{"grow":""}},[('title' in _vm.$slots)?_c('h3',{staticClass:"title font-weight-bold wb-bw"},[_vm._t("title")],2):_vm._e(),('subtitle' in _vm.$slots)?_c('h5',{staticClass:"font-weight-bold wb-bw"},[_vm._t("subtitle")],2):_vm._e()])],1)],1):_vm._e(),('content' in _vm.$slots)?_c(VCardText,{staticClass:"content",class:{ 'no-padding': _vm.contentWithoutPadding }},[_vm._t("content")],2):_vm._e(),('actions' in _vm.slots)?_c(VCardActions,{staticClass:"mt-3"},[_c(VRow,{attrs:{"no-gutters":""}},[_vm._t("actions")],2)],1):_vm._e()],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }