<template lang="pug">
  v-navigation-drawer(
    :value="value"
    width="100%"
    app
    absolute
    floating
    :style="{ zIndex: 4 }"
    :color="$vuetify.theme.currentTheme.drawer"
  )
    block-profile-menu-content(
      :show-about-us="false"
      class="drawer-mobile-content-offset"
      @closeDrawer="$emit('closeDrawer')"
    )
</template>

<script>
import ESvgIcon from '~/components/elements/icons/e-svg-icon'
import BlockProfileMenuContent from '~/modules/profile/views/components/block-profile-menu-content'

export default {
  name: 'DrawerMobile',
  components: {
    BlockProfileMenuContent,
    ESvgIcon
  },
  props: {
    value: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped lang="scss">
.drawer-mobile-content-offset {
  padding-top: 56px;
}
</style>
