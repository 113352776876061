import OrganizationInvite from '~/modules/organizations/models/OrganizationInvite'

export default function organizationInvite ({ route }) {
  if (route.query?.invite) {
    OrganizationInvite.insert({
      data: {
        id: route.query?.invite
      }
    })
  }
}
