<template lang="pug">
  v-row(class="h-100")
    v-col(cols="12")
      v-container(class="h-100")
        v-row(class="h-100")
          v-col(cols="12" class="h-100 d-flex align-center justify-center")
            div(class="error-card")
              div(class="error-card__image")
                v-img(:src="require(`~/assets/images/racoons/${errorImage}.svg`)" max-width="400")
              div(class="error-card__content")
                p(class="error-card__text" :class="{ 'no-access': noAccessError }") {{ $t(errorMessage) }}
              div(class="error-card__actions")
                v-btn(class="secondary-button" @click="$router.push('/documents')") {{ $t('Come back to main page') }}
</template>
<script>

export default {
  layout: 'layoutWithoutDrawer',
  props: {
    // eslint-disable-next-line vue/require-default-prop
    error: [String, Error, Object]
  },
  computed: {
    errorMessage () {
      return this._.get(this.error, 'response.data.message') || this._.get(this.error, 'message')
    },
    errorCode () {
      return this._.get(this.error, 'response.data.code')
    },
    noAccessError () { // todo: remove it when design is ready
      return this.errorCode === 403
    },
    errorImage () {
      return this.noAccessError ? 'racoon-1' : 'racoon-404'
    }
  }
}
</script>
<style lang="scss">
.error-card {
  max-width: 500px;
  width: 100%;

  display: flex;
  padding: 40px;
  flex-direction: column;
  align-items: center;
  gap: 32px;

  border-radius: 28px;
  background: var(--bg-primary, #FFF);

  text-align: center;

  &__text {
    color: #333;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 24px;

    &.no-access {
      max-width: 310px;
      font-size: 24px;
      font-weight: 700;
    }
  }
}
</style>
