<template lang="pug">
  t-dialog(v-bind="$attrs" v-on="$listeners")
    template(#title)  {{ $t(title) }}
    template(#content)
      template(v-if="!_.isEmpty(popularItems)")
        div(class="title ml-4")
          | {{$t('Frequently used units')}}
        v-data-table(
          :loading-text="$t('Loading... Please wait')"
          v-model="selected"
          :items="popularItems"
          :headers="tableHeaders"
          single-select
          class="elevation-0 m-orm-list-table"
          show-select
          multi-sort
        )
          template(v-slot:item="scopedItemProps")
            t-orm-item(:scopedItem="scopedItemProps"
              :model="dictionaryModel"
              :config="tableItemConfig"
              context="orm-select-dialog")

        v-btn( color="primary" @click="showAllItems=!showAllItems")
          | {{showAllItemsButtonText}}
      template(v-if="(_.isEmpty(popularItems) || showAllItems) && !localLoading")
        div(class="title mt-5 ml-4")
          | {{$t('Other units')}}
        v-data-table(
          :loading-text="$t('Loading... Please wait')"
          @input="setPopular"
          :search="filters ? filters.search : null"
          v-model="selected"
          :items="notPopularItems"
          :headers="tableHeaders"
          single-select
          class="elevation-0 m-orm-list-table"
          show-select
          multi-sort
          ref="table"
        )
          template(v-slot:top)
            v-col(v-if="dictionaryModel.isFilterable || dictionaryModel.ormFilters" cols="12" class="py-0")
              t-orm-filters(v-model="filters" :model="dictionaryModel")
          template(v-slot:item="scopedItemProps")
            t-orm-item(:scopedItem="scopedItemProps"
              :model="dictionaryModel"
              :config="tableItemConfig"
              context="orm-select-dialog")
      template(v-if="localLoading")
        e-progress-linear
    template(#actions)
      t-orm-buttons(:items="ormButtons")
</template>

<script>
// This dialog was created specially for units dictionary
// TODO we must create more abstract component if we will have the same business logic in other place in future
import TDialog from '~/components/templates/t-dialog'
import MOrmSelectTable from '~/components/modules/tables/orm/m-orm-select-table'
import TOrmButtons from '~/components/templates/orm/t-orm-buttons'
import TOrmItem from '~/components/templates/orm/t-orm-item'
import filtration from '~/mixins/tables/filtration'
import searching from '~/mixins/tables/searching'
import EProgressLinear from '~/components/elements/progress/e-progress-linear'

export default {
  components: {
    EProgressLinear,
    TOrmItem,
    MOrmSelectTable,
    TDialog,
    TOrmButtons
  },
  mixins: [filtration, searching],
  props: {
    title: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    },
    config: {
      type: Object,
      required: true
    },
    popularFieldName: {
      type: String,
      required: true
    },
    dictionaryModel: {
      type: Function,
      default: () => {}
    }
  },
  data: () => ({
    filters: null,
    showAllItems: false,
    popularItemsCodes: [],
    selected: [],
    created: [],
    localLoading: false
  }),
  computed: {
    model () {
      return this.dictionaryModel
    },
    showAllItemsButtonText () {
      return this.showAllItems ? this.$t('Hide other units') : this.$t('Show other units')
    },
    tableHeaders () {
      let headers = this.dictionaryModel.ormHeaders

      if (this.short && this.dictionaryModel.isShortAble) {
        headers = headers.filter(header => this.dictionaryModel.getShortHeaders().includes(header.value))
      }

      return this._.map(headers, item => (Object.assign({}, {
        ...item
      }, {
        text: this.$t(item.text)
      })))
    },
    tableItemConfig () {
      return {
        checkbox: true,
        short: true,
        disabled: scopedItem => !scopedItem.isSelected,
        dblclick: (value, scopedItem) => {
          if (!this.selected.includes(value)) {
            if (this.config.single) {
              this.selected = [value]
            } else {
              this.selected.push(value)
            }
          } else {
            const removedId = this.selected
              .map(item => item.$id)
              .indexOf(value.$id)
            this.selected.splice(removedId, 1)
          }
        },
        actions: [
          //
        ]
      }
    },
    popularItems () {
      return this.dictionaryModel.query().whereIdIn(this.popularItemsCodes).all()
    },
    notPopularItems () {
      return this.dictionaryModel.query().except([...this.popularItemsCodes, ...this.created]).all()
    },
    modelSortParams () {
      return this.dictionaryModel.getSortParams()
    },
    tableConfig () {
      const attrs = {
        loading: this.isLoading,
        dictionary: this.dictionaryModel
      }

      if (this.filter) {
        attrs.defaultFilter = this.filter
      }

      return Object.assign(
        this.$config.table,
        this.modelSortParams,
        {
          attrs,
          single: this.singleSelect
        })
    },
    isLoading () {
      return this.loading || this.localLoading
    },
    ormButtons () {
      return [
        {
          text: 'Cancel selected',
          attrs: {
            color: 'primary',
            class: ['mr-4'],
            text: true,
            depressed: true,
            disabled: !this.selected.length
          },
          call: () => this.clear(true)
        },
        {
          text: 'Select',
          attrs: {
            color: 'primary',
            depressed: true,
            disabled: !this.selected.length
          },
          loading: this.localLoading,
          call: this.select
        }
      ]
    },
    filter () {
      if (this.config.filter) {
        return {
          [this.config.filter]: this.created
        }
      }
      return null
    },
    listeners () {
      const vm = this
      return Object.assign({},
        this.$listeners,
        {
          input (event) {
            vm.$emit('input', event)
            vm.setInitialData()
          }
        }
      )
    }
  },
  async created () {
    this.localLoading = true
    await this.dictionaryModel.api().active().all()
    this.localLoading = false
    await this.setInitialData()
  },
  methods: {
    setInitialData () {
      this.popularItemsCodes = this.dictionaryModel.query().where(this.popularFieldName, true).except(this.created).get().map(item => item.code)
      this.showAllItems = false
    },
    close () {
      this.$emit('input', false)
      this.setInitialData()
    },

    setPopular (value) {
      if (this.singleSelect) {
        for (let i = 0; i < this.$refs.table.rows.length; i++) {
          this.$refs.table.rows[i][this.popularFieldName] = false
        }
      }
      for (let i = 0; i < value.length; i++) {
        value[i][this.popularFieldName] = true
      }
    },

    select () {
      if (!this.popularItemsCodes.includes(this.selected[0].code) && this.selected[0].frequentlyUsed) {
        this.model.api().update(this.selected[0], { frequentlyUsed: true })
      }
      this.$emit(['update', 'selection'].join(':'), this.selected)
      this.close()
    },

    fill (context, items) {
      if (this._.isArray(items) && items.length) {
        let ormFilter = this.config.filter
        if (ormFilter.includes('.')) {
          const arr = this.config.filter.split('.')
          ormFilter = arr[arr.length - 1]
        }

        this.created = this._.map(items, ormFilter)
      } else {
        this.selected = items
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .m-orm-list-table {
    >>> tr {
      &:nth-child(2n - 1) {
        background-color: #F0F6FC;
      }
    }
  }
</style>
