import { VBtn } from 'vuetify/lib/components/VBtn';
import { VForm } from 'vuetify/lib/components/VForm';
import { VImg } from 'vuetify/lib/components/VImg';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"profile-api-keys-form"},[(_vm.showCopyBlock)?_c('div',{staticClass:"profile-api-keys-form__image"},[_c(VImg,{attrs:{"src":require('~/assets/images/key.png'),"max-width":"80"}})],1):_vm._e(),_c('h2',{staticClass:"title-32px",class:[_vm.showCopyBlock ? 'mb-2' : 'mb-8']},[_vm._v(_vm._s(_vm.title))]),(_vm.showCopyBlock)?_c('div',{staticClass:"text-16px mb-8"},[_vm._v(_vm._s(_vm.$t('Managing key, you set an integration')))]):_vm._e(),(_vm.showCopyBlock)?_c('block-profile-api-keys-copy',{attrs:{"api-key":_vm.apiKey},on:{"close":_vm.closeModal}}):_c(VForm,{on:{"submit":function($event){$event.preventDefault();$event.stopPropagation();return _vm.handleSubmit($event)}}},[_c('ValidationObserver',{ref:"form",attrs:{"slim":""}},[_c('ValidationProvider',{attrs:{"vid":"title","name":_vm.wrapQuotesProviderName(_vm.$t('Key name')),"mode":"lazy"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c(VTextField,{attrs:{"filled":"","error-messages":errors,"label":_vm.$t('Enter key name (not required)')},model:{value:(_vm.form.title),callback:function ($$v) {_vm.$set(_vm.form, "title", $$v)},expression:"form.title"}})]}}])}),_c('div',{staticClass:"profile-api-keys-form__actions"},[_c(VBtn,{attrs:{"height":"48"},on:{"click":_vm.closeModal}},[_vm._v(_vm._s(_vm.$t('Cancel')))]),_c(VBtn,{staticClass:"main-button",attrs:{"type":"submit","loading":_vm.loading}},[_vm._v(_vm._s(_vm.buttonText))])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }