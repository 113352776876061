import { Model } from '@vuex-orm/core'
import Administration from '~/models/abstracts/Administration'
import ChainInheritance from '~/models/mixins/ChainInheritance'
import SoftDeletAble from '~/models/mixins/SoftDeletAble'
import PersistAble from '~/models/mixins/PersistAble'
import TimestampAble from '~/models/mixins/TimestampAble'

export default class User extends ChainInheritance(Administration, [
  SoftDeletAble,
  TimestampAble,
  PersistAble
]) {
  static entity = 'users'
  static paginated = true
  static ormLoadWithRelations = true
  static defaultSortParam = 'dateCreated'
  static defaultSortOrder = true
  static persistOptions = {
    insertOrUpdate: ['organization']
  }

  static ROLES = {
    user: 'ROLE_USER',
    owner: 'ROLE_OWNER',
    superAdmin: 'ROLE_SUPER_ADMIN'
  }

  static ROLE_NAMES = {
    [User.ROLES.owner]: 'Власник',
    [User.ROLES.superAdmin]: 'Супер Адмін'
  }

  static fields () {
    return super.fields({
      id: this.attr(null),
      email: this.attr(null),
      login: this.attr(null),
      roles: this.attr([]),
      name: this.attr(null),
      phone: this.attr(null),
      currentOrganizationId: this.attr(null)
    })
  }

  get rolesString () {
    let roles = ''
    this.roles.map((role, key) => {
      if (role !== User.ROLES.user) {
        roles += User.ROLE_NAMES[role] + ' '
      }
    })

    return roles || '-'
  }

  get isUser () {
    return this.roles.includes(User.ROLES.user)
  }

  get isSuperAdmin () {
    return this.roles.includes(User.ROLES.superAdmin)
  }

  static ormTrans = {
    single: 'user',
    multy: 'users'
  }

  static ormHeaders = [
    {
      text: 'Name',
      value: 'name',
      sortable: false,
      filterable: false
    },
    {
      text: 'E-mail',
      value: 'email',
      sortable: false,
      filterable: false
    },
    {
      text: 'Login ',
      value: 'login',
      sortable: false,
      filterable: false
    },
    {
      text: 'Phone',
      value: 'phone',
      sortable: false,
      filterable: false
    },
    {
      text: 'Organizations',
      value: 'organizationsNames',
      sortable: false
    },
    {
      text: 'Actions',
      align: 'center',
      value: 'actions',
      width: '72',
      sortable: false,
      filterable: false
    }
  ]

  static getSortParams () {
    return super.getSortParams({
      'sort-by': ['active'],
      'sort-desc': [true]
    })
  }

  static ormFields = [
    {
      model: 'firstName',
      component: 'v-text-field',
      provider: {
        vid: 'firstName',
        name: 'First name',
        rules: 'required'
      },
      attrs: {
        label: 'First name',
        outlined: true
      }
    },
    {
      model: 'lastName',
      component: 'v-text-field',
      provider: {
        vid: 'lastName',
        name: 'Last name',
        rules: 'required'
      },
      attrs: {
        label: 'Last name',
        outlined: true
      }
    },
    {
      model: 'email',
      component: 'v-text-field',
      provider: {
        vid: 'email',
        name: 'E-mail',
        rules: 'required|email'
      },
      attrs: {
        label: 'E-mail (login)',
        type: 'email',
        outlined: true
      }
    },
    {
      model: 'login',
      component: 'v-text-field',
      provider: {
        vid: 'login',
        name: 'Login ',
        rules: 'required'
      },
      attrs: {
        label: 'Login ',
        type: 'text',
        outlined: true
      }
    },
    {
      model: 'phone',
      component: 'e-input-mask',
      provider: {
        vid: 'phone',
        name: 'Phone',
        rules: 'required|phone'
      },
      attrs: {
        label: 'Phone',
        mask: 'mobile',
        outlined: true
      }
    }
  ]

  static ormActions = [
    {
      name: 'login'
    },
    {
      name: 'edit'
    },
    {
      name: 'read',
      text: 'View',
      icon: {
        text: 'info',
        type: 'e-svg-icon'
      }
    }
  ]

  static ormDialogs = {
    default: 'm-orm-crud-dialog',
    edit: 'm-orm-crud-dialog',
    read: 'm-orm-view-dialog'
  }

  static ormDialogsConfig = {
    edit: {
      config: {
        context: 'update'
      }
    },
    read: {
      title: item => ({ name: item.fullName }),
      config: {
        context: 'read',
        fields: [
          {
            model: 'name',
            label: 'Name',
            value: val => val || '-'
          },
          {
            model: 'login',
            label: 'Login '
          },
          {
            model: 'email',
            label: 'Email',
            value: val => val || '-'
          },
          {
            model: 'phone',
            label: 'Phone',
            value: val => val || '-'
          },
          {
            model: 'promotionCode',
            label: 'Promotion'
          },
          {
            model: 'rolesString',
            label: 'Roles'
          }
        ]
      }
    }
  }

  static apiConfig = {
    get actions () {
      const configActions = Object.assign({}, Model.apiConfig.actions)
      configActions.addManagedOrganization = function (id, payload) {
        return this.put(
          Model.$routes.users.addManagedOrganization(id),
          payload
        )
      }
      configActions.getCurrent = function () {
        return this.get(Model.$routes.users.profile(), {
          dataKey: null,
          save: true,
          persistBy: 'create',
          persistOptions: {
            insertOrUpdate: ['organization']
          }
        })
      }
      configActions.updateUserProfile = function (payload) {
        return this.put(Model.$routes.users.profile(), payload)
      }
      configActions.updateUserPassword = function (payload) {
        return this.post(Model.$routes.users.changePassword(), payload)
      }
      configActions.getCode = function (payload) {
        return this.post(Model.$routes.users.getCode(), payload)
      }
      configActions.setCurrentOrganization = function (payload) {
        return this.put(Model.$routes.users.currentOrganization(), payload)
      }
      return configActions
    }
  }
}
