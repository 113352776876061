<template lang="pug">
  div(class="organizations-delete")
    div(class="organizations-delete__image")
      v-img(:src="require('~/assets/images/warning.png')" :max-width="xsDevice ? 56 : 80")
    div(class="organizations-delete__content")
      h2(class="mb-2" :class="[xsDevice ? 'title-20px': 'title-32px']") {{ $t(title) }}
      div(class="text-14px" :class="{ 'pb-4': isDelete }") {{ $t(text) }}
      template(v-if="isDelete")
        v-divider
        div(class="text-14px pt-4") 🕧 {{ $t('The organization will be deleted at 11:59 p.m. the same day if you delete it before 6:00 p.m., or at 11:59 p.m. the next day if after 6:00 p.m.') }}
      div(v-if="false" class="d-flex align-center")
        v-switch(
          v-model="isExportDocuments"
          hide-details
        )
        div(class="text-14px dark") {{ $t('Export documents before deletion') }}
    div(class="organizations-delete__actions")
      v-btn(
        class="organizations-delete__reject-action"
        height="48"
        @click="closeModal(false)"
      ) {{ isDelete ? $t('Cancel') : $t('cancel') }}
      v-btn(
        class="secondary-button error-btn organizations-delete__reject-approve"
        :loading="loading"
        @click="submit"
      ) {{ isDelete ? $t('Confirm delete') : $t('Cancel delete') }}
</template>

<script>
import responsive from '~/mixins/pages/responsive'

export default {
  name: 'BlockOrganizationsDelete',
  mixins: [responsive],
  props: {
    closeModal: {
      type: Function,
      default: () => {}
    },
    title: {
      type: String,
      default: null
    },
    text: {
      type: String,
      default: null
    },
    isDelete: {
      type: Boolean,
      default: false
    },
    onSubmit: {
      type: Function,
      default: null
    }
  },
  data () {
    return {
      loading: false,
      isExportDocuments: false
    }
  },
  methods: {
    async submit () {
      try {
        this.loading = true
        if (typeof this.onSubmit === 'function') {
          await this.onSubmit()
        }
        this.closeModal(true)
      } catch (e) {
        this.$handlers.error(e, this)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
.organizations-delete {
  &__image {
    display: flex;
    justify-content: center;
    margin-bottom: 16px;

    @media (min-width: map-get($breakpoints, 'md')) {
      margin-bottom: 24px;
    }
  }
  &__actions {
    display: grid;
    grid-template-rows: 1fr 1fr;
    gap: 16px;

    padding-top: 32px;

    @media (min-width: map-get($breakpoints, 'md')) {
      grid-template-columns: 1fr 1fr;
      grid-template-rows: none;
    }
  }
  &__reject-action {
    @media (max-width: map-get($breakpoints, 'sm')) {
      grid-row: 2;
    }
  }
  &__approve-action {
    @media (max-width: map-get($breakpoints, 'sm')) {
      grid-row: 1;
    }
  }
}
</style>
