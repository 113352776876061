<template lang="pug">
  v-dialog(
    v-bind="$attrs"
    v-on="$listeners"
    :value="isOpened"
    :max-width="maxWidth"
    scrollable
    persistent
  )
    div(
      v-if="isOpened"
      class="dialog__content-flex"
    )
      v-btn(
        class="dialog-eds-iit-checkbox__close-btn"
        color="rgba(22, 27, 37, 0.5)"
        v-bind="$attrs"
        @click="closeDialog()"
        x-small
        fab
        text
      )
        v-icon(size="24") mdi-close
      div(class="dialog__left")
        eds-iit-checkbox(
          @confirm="closeDialog"
          @stepChange="handleStepChange"
          :on-confirm="onConfirm"
          :type="pluginType"
          :on-interface-action-error="handleInterfaceActionError"
          ref="edsIitCheckbox"
          :show-cached-data-message="showCachedDataMessage"
          :only-file-sign="onlyFileSign"
          :titles="pluginTitles"
          :read-diia-key="readDiiaKey"
          :save-data-interface-after-close="saveDataInterfaceAfterClose"
        )
</template>

<script>
import EdsIitCheckbox from '~/modules/eds-iit-checkbox/views/eds-iit-checkbox'
import Dialog from '~/models/system/Dialog'
import ESvgIcon from '~/components/elements/icons/e-svg-icon'
import responsive from '~/mixins/pages/responsive'
import edsIitCheckboxSteps from '~/modules/eds-iit-checkbox/mixins/edsIitCheckboxSteps'

export default {
  name: 'DialogEdsIitCheckbox',
  components: {
    EdsIitCheckbox,
    ESvgIcon
  },
  mixins: [responsive, edsIitCheckboxSteps],
  props: {
    type: {
      type: String,
      default: 'edsIitCheckboxDialog'
    },
    showHintBlock: {
      type: Boolean,
      default: true
    }
  },
  data: () => ({
    currentStep: 'options'
  }),
  computed: {
    dialog () {
      return Dialog.query().where('type', this.type).first()
    },
    isOpened () {
      return this._.get(this.dialog, 'isOpened', false)
    },
    params () {
      return this._.get(this.dialog, 'params')
    },
    pluginType () {
      return this._.get(this.params, 'pluginType')
    },
    onlyFileSign () {
      return this._.get(this.params, 'onlyFileSign')
    },
    width () {
      return this._.get(this.params, 'width', '724px')
    },
    showCachedDataMessage () {
      return this._.get(this.params, 'showCachedDataMessage', null)
    },
    pluginTitles () {
      return this._.get(this.params, 'pluginTitles', null)
    },
    readDiiaKey () {
      return this._.get(this.params, 'readDiiaKey', false)
    },
    onConfirm () {
      return this._.get(this.params, 'onConfirm')
    },
    showHint () {
      return this._.get(this.params, 'showHintBlock') || this.showHintBlock
    },
    maxWidth () {
      return this.currentStep === 'options' ? '512px' : '724px'
    },
    saveDataInterfaceAfterClose () {
      return this._.get(this.params, 'saveDataInterfaceAfterClose')
    }
  },
  watch: {
    isOpened (val) {
      if (val) {
        document.addEventListener('keyup', this.onKeyUp)
      } else {
        document.removeEventListener('keyup', this.onKeyUp)
        this.currentStep = this.steps.options
      }
    }
  },
  created () {
    if (!this.dialog) {
      Dialog.insert({
        data: {
          type: this.type
        }
      })
    }
  },
  methods: {
    handleStepChange (step) {
      this.currentStep = step
    },
    async closeDialog (data) {
      await this.dialog.close(data, true)
    },
    async handleInterfaceActionError (err) {
      this.$handlers.error(err, this)
      await this.closeDialog()
    },
    onKeyUp (e) {
      if (e.key === 'Escape') {
        this.closeDialog()
      }
    }
  }
}
</script>

<style scoped lang="scss">
.dialog-eds-iit-checkbox {
  &__close-btn {
    position: absolute !important;
    top: 12px;
    right: 12px;
    z-index: 2;
  }
}
</style>
