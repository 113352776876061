<template lang="pug">
  div
    introduction-guideline
</template>

<script>
import IntroductionGuideline from '~/modules/guideline/components/introduction-guideline.vue'
export default {
  name: 'Guidelines',
  components: {
    IntroductionGuideline
  }
}
</script>

<style scoped lang="scss">

</style>
